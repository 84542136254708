import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Switch } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '~/store/hooks';

import styles from './CopyModal.module.scss';

type CopyButtonProps = {
  form: any;
  currentCopyKey: string;
  targetCopyKey: string;
};

const CopyButton = ({ form, currentCopyKey, targetCopyKey }: CopyButtonProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const copy = () => {
    if (form.getFieldValue(currentCopyKey) === '-') {
      return;
    }

    form.setFieldsValue({ [targetCopyKey]: form.getFieldValue(currentCopyKey) });
    setIsCopied(true);
  };

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), 2000);
    }
  }, [isCopied]);

  return <Button icon={isCopied ? <CheckOutlined /> : <CopyOutlined />} onClick={copy} />;
};

type Values = {
  name: string;
  revision: boolean;
};

type CopyModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: (values: Values) => void;
  tenant: any;
};

export const CopyModal = ({ isOpen, onCancel, onConfirm, tenant }: CopyModalProps) => {
  const [form] = Form.useForm();
  const assemblyWizard = useAppSelector((state) => state.assemblyWizard);

  const [isRevision, setIsRevision] = useState(false);

  const defaultValues = useMemo(() => {
    return {
      is_revision: false,
      currentAssemblyName: assemblyWizard.name,
      currentDrawingNumber: assemblyWizard.drawingNumber || '-',
      currentDrawingRevision: assemblyWizard.drawingRevision || '-',
      user_drawing_number: undefined,
      user_drawing_revision: undefined,
    };
  }, [assemblyWizard]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(defaultValues);
  }, [defaultValues, form]);

  return (
    <Modal
      forceRender
      cancelText="Cancel"
      okText="Copy"
      title="Copy Assembly"
      open={isOpen}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            if (values.is_revision === false && values.name !== '') {
              onConfirm(values);
            }
            // validate current drawing revision si not same with user_drawing_revision
            if (values.currentDrawingRevision === values.user_drawing_revision) {
              form.setFields([
                {
                  name: 'user_drawing_revision',
                  errors: ['Drawing revision must be different from current drawing revision!'],
                },
              ]);
              return;
            }
            onConfirm(values);
          })
          .catch((info) => console.log(info));
      }}
    >
      <Form form={form} labelCol={{ span: 10 }} layout="horizontal">
        <div className={styles.groupCopyButton}>
          <Form.Item
            className={styles.formInputCopy}
            label="Current Assembly Name"
            name="currentAssemblyName"
          >
            <Input disabled />
          </Form.Item>
          <CopyButton form={form} currentCopyKey="currentAssemblyName" targetCopyKey="name" />
        </div>
        <Form.Item
          label="New Assembly Name"
          name="name"
          rules={[{ required: true, message: 'Please input the name of the copied assembly!' }]}
        >
          <Input placeholder="e.g. ROV to Camera" />
        </Form.Item>
        <Form.Item label="New Revision?" name="is_revision" valuePropName="checked">
          <Switch
            onChange={(checked: boolean) => {
              if (checked) {
                form.setFieldsValue({ user_drawing_number: assemblyWizard.drawingNumber });
              } else {
                form.setFieldsValue({ user_drawing_number: undefined });
              }
              setIsRevision(checked);
            }}
          />
        </Form.Item>
        {isRevision && (
          <>
            <div className={styles.groupCopyButton}>
              <Form.Item
                className={styles.formInputCopy}
                label="Current Drawing Number"
                name="currentDrawingNumber"
              >
                <Input disabled />
              </Form.Item>
              <CopyButton
                form={form}
                currentCopyKey="currentDrawingNumber"
                targetCopyKey="user_drawing_number"
              />
            </div>
            <Form.Item label="New Drawing Number" name="user_drawing_number">
              <Input disabled={tenant.is_auto_drawing_number} />
            </Form.Item>
            <Form.Item label="Current Drawing Revision" name="currentDrawingRevision">
              <Input disabled />
            </Form.Item>
            <Form.Item label="New Drawing Revision" name="user_drawing_revision">
              <Input />
            </Form.Item>
          </>
        )}
      </Form>
    </Modal>
  );
};
