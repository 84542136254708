import { JSONSchema7Object } from 'json-schema';

import styles from './WizardCableTestCard.module.scss';

import { FormSchema } from '~/components/FormSchema/FormSchema';
import { AppState } from '~/store/reducers';
import { wizardSetCableTestCardData } from '~/store/actions/wizard/Action';
import { useAppDispatch, useAppSelector } from '~/store/hooks';
import { useEffect, useState } from 'react';

const WizardCableTestCard = () => {
  const dispatch = useAppDispatch();
  const { cableTestCardData, drawingNumber, drawingRevision, drawingnumberdata } = useAppSelector(
    (state: AppState) => state.assemblyWizard,
  );
  const tenantConfigData = useAppSelector((state: AppState) => state.data.tenantConfig.data);
  const schema = tenantConfigData?.cable_test_card_schema
    ? (JSON.parse(tenantConfigData?.cable_test_card_schema) as JSONSchema7Object)
    : {};
  const uiSchema = tenantConfigData?.cable_test_card_ui_schema
    ? JSON.parse(tenantConfigData?.cable_test_card_ui_schema)
    : {};

  const [data, setData] = useState(cableTestCardData);

  useEffect(() => {
    if (drawingNumber && drawingRevision) {
      const newdnum = `${drawingnumberdata.prefix}${drawingNumber}${drawingnumberdata.suffix}`;
      // console.log(newdnum, drawingNumber, 'drawing number', drawingRevision, drawingnumberdata);

      setData({
        ...data,
        drawingInfo: {
          drawingNumber: newdnum,
          drawingRevision: drawingRevision,
        },
      });
    }
  }, [data, drawingNumber, drawingRevision, drawingnumberdata]);

  useEffect(() => {
    dispatch(wizardSetCableTestCardData(data));
  }, [data, dispatch]);

  useEffect(() => {
    if (cableTestCardData) {
      setData(cableTestCardData);
    }
  }, [cableTestCardData]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Cable Test Card</h1>
      </div>
      <FormSchema
        cancelAction={() => {
          throw new Error('Function not implemented.');
        }}
        confirmAction={() => {
          throw new Error('Function not implemented.');
        }}
        formData={data}
        schema={schema}
        uiSchema={uiSchema}
        showActions={false}
        onChange={(data) => dispatch(wizardSetCableTestCardData(data.formData))}
      />
    </div>
  );
};

export default WizardCableTestCard;
