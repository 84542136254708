import { FolderOpenOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, message, Modal, Space } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { mxGraph } from '@anekonnect/mxgraph';
import { FormInstance } from 'antd/lib/form';
import { v4 as uuidv4 } from 'uuid';

import { AssemblyForm } from '../Types';
import WizardHeaderSaveAssembly from '../WizardHeaderSaveAssembly';

import { deleteAssembly } from '~/api/AuthorizedDeletes';
import { postAsBuiltAssembly, postCopyAssembly } from '~/api/AuthorizedPosts';
import { defaultErrorHandler } from '~/api/HttpError';
import { CopyModal } from '~/components/Modals';
import { LINK_ASSEMBLY_NEW } from '~/constants/paths';
import { AppState } from '~/store/reducers';
import {
  wizardResetDrawingData,
  wizardUpdateAssemblyVisibility,
} from '~/store/actions/wizard/Action';
import { getPaymentStatus, paymentStatusIsPaid } from '~/utils/helper';
import MenuButton from './MenuButton';
import { useAppSelector } from '~/store/hooks';
import { AsBuiltModal } from '~/components/Modals/AsBuiltModal';
import { VisibilityModal } from '~/components/Modals/VisibilityModal';
import { putChangeVisibility } from '~/api/AuthorizedPuts';
import { snackBarOpen } from '~/store/actions/ui/SnackBar';
import { useDispatch } from 'react-redux';
import JSZip from 'jszip';
import { mx } from '~/constants/wizard';

// import { VsdxImporter } from './VsdxConverter';
// import { XMLParser } from 'fast-xml-parser';

// interface ShapeCell {
//   '@Name': string;
//   '#text': string;
// }

// interface Shape {
//   ID: string;
//   Text?: { '#text': string };
//   Cell?: ShapeCell[];
//   ShapeType?: string; // Adjust based on actual data structure
// }

// interface Connector {
//   Source: { ID: string };
//   Target: { ID: string };
// }

// interface PageContents {
//   Shapes: { Shape: Shape[] };
//   Connectors: { Connector: Connector[] };
// }

// interface VsdxData {
//   PageContents: PageContents;
// }
type PropsFromState = {
  assemblyId?: number;
  whoamiData: {
    payment_status?: string;
    type?: string;
  };
  tenant: {
    is_auto_drawing_number?: boolean;
  };
};

type PropsFromDispatch = {
  wizardResetDrawingData: typeof wizardResetDrawingData;
};

type MenuFileProps = {
  graph: mxGraph;
  assemblyForm: FormInstance<AssemblyForm>;
} & PropsFromState &
  PropsFromDispatch;

const MenuFile = ({
  graph,
  assemblyForm,
  assemblyId,
  whoamiData,
  tenant,
  wizardResetDrawingData,
}: MenuFileProps) => {
  const navigate = useNavigate();

  const assemblyStatus = useAppSelector((state) => state.assemblyWizard.status);
  const isTenant = useAppSelector((state) => state.data.tenantConfig.isTenant);
  const isAssembleyPublic = useAppSelector((state) => state.assemblyWizard.isPublic);
  const [isAssemblyVisibile, setIsAssemblyVisibile] = useState(isAssembleyPublic);
  const [isOpenCopyModal, setIsOpenCopyModal] = useState(false);
  const [isOpenAsBuiltModal, setIsOpenAsBuiltModal] = useState(false);
  const [isOpenVisibilityModal, setIsOpenVisibilityModal] = useState(false);
  const paymentStatus = getPaymentStatus(whoamiData);
  const isPaid = paymentStatusIsPaid(paymentStatus);

  useEffect(() => {
    setIsAssemblyVisibile(isAssembleyPublic);
  }, [isAssembleyPublic]);

  const dispatch = useDispatch();

  const fileInputRef = useRef<HTMLInputElement>(null);

  // const inspectVsdxStructure = async (file: File): Promise<void> => {
  //   try {
  //     const zip = await JSZip.loadAsync(file);
  //     zip.forEach((relativePath) => {
  //       console.log('File path in zip:', relativePath);
  //     });
  //   } catch (error) {
  //     console.error('Error reading VSDX file structure:', error);
  //   }
  // };

  const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      const zip = await JSZip.loadAsync(file);
      const documentXml = await zip.file('visio/document.xml')?.async('text');
      const page1 = await zip.file('visio/pages/page1.xml')?.async('text');
      if (!documentXml || !page1) {
        console.error('Required XML files not found in the VSDX archive.');
        return;
      }
      // console.log(page1, 'vsdx meta data');

      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(page1, 'text/xml');

      const shapes = xmlDoc.getElementsByTagName('Shape');
      const connections = xmlDoc.getElementsByTagName('Connect');

      const parent = graph.getDefaultParent();
      graph.getModel().beginUpdate();

      try {
        const vertices: Record<string, any> = {};

        const startX = 10; // Starting X position
        const startY = -300; // Starting Y position
        const scaleFactor = 100; // Scale factor for spacing and size
        const graphHeight = 800; // Set this to the height of your graph area for filp vertically
        Array.from(shapes).forEach((shape) => {
          const originalId = shape.getAttribute('ID');
          const id = `drawing_tools_shape_${originalId}_${uuidv4()}`;

          const textElement = shape.getElementsByTagName('Text')[0];
          const label = textElement ? textElement.textContent?.trim() || '' : '';
          if (id)
            if (!vertices[id]) {
              const configs = {
                connectable: true,
                deleteable: true,
                editable: false,
                mirrorable: true,
                moveable: true,
                resizeable: true,
                rotateable: true,
              };
              const doc = mx.mxUtils.createXmlDocument();
              const node = doc.createElement('component');
              node.setAttribute('label', label);
              node.setAttribute('configs', JSON.stringify(configs));
              // console.log(node, 'new node data');

              const pinX = shape.getElementsByTagName('Cell')[0]?.getAttribute('V');
              const pinY = shape.getElementsByTagName('Cell')[1]?.getAttribute('V');

              // Calculate position with scaling and initial offset
              const x = startX + (pinX ? parseFloat(pinX) : 0) * scaleFactor;
              const y = graphHeight - (startY + (pinY ? parseFloat(pinY) : 0) * scaleFactor);

              // Determine shape type and dimensions
              let borderround = 0;
              let angle = 0;
              let perimeter = 'rectanglePerimeter'; //perimeter=ellipsePerimeter;
              let shapeType:
                | 'rectangle'
                | 'rhombus'
                | 'connector'
                | 'triangle'
                | 'ellipse'
                | 'roundedRectangle'
                | 'circle' = 'rectangle'; // Default to rectangle
              const shapewidth = shape.getElementsByTagName('Cell')[2]?.getAttribute('V') || '1';
              const shapeheight = shape.getElementsByTagName('Cell')[3]?.getAttribute('V') || '1';

              // Check for rounding and determine shape dimensions
              for (const cell of shape.getElementsByTagName('Cell')) {
                if (cell.getAttribute('N') === 'Rounding') {
                  borderround = 1;
                }
                if (cell.getAttribute('N') === 'Angle') {
                  const ang = cell.getAttribute('V');
                  if (ang) angle = parseFloat(ang) * 19.1;
                  // console.log(angle, 'angle data');
                }
              }

              let width = parseFloat(shapewidth);
              let height = parseFloat(shapeheight);
              const shapescale = 100;

              // Adjust width and height based on geometry
              const geometrySection = shape.getElementsByTagName('Section');
              for (const section of geometrySection) {
                if (section.getAttribute('N') === 'Geometry') {
                  const rows = section.getElementsByTagName('Row');
                  for (const row of rows) {
                    if (row.getAttribute('T') === 'Ellipse') {
                      shapeType = 'ellipse';
                      const a = parseFloat(
                        row.getElementsByTagName('Cell')[0]?.getAttribute('V') || '0',
                      );
                      const b = parseFloat(
                        row.getElementsByTagName('Cell')[1]?.getAttribute('V') || '0',
                      );
                      width = a * 2 * shapescale; // Full width
                      height = b * 2 * shapescale; // Full height
                      perimeter = 'ellipsePerimeter';
                    }
                    if (row.getAttribute('T') === 'RelMoveTo') {
                      let relLineToCount = 0;

                      // Loop through the rows
                      for (let i = 0; i < rows.length; i++) {
                        const row = rows[i];
                        const type = row.getAttribute('T');

                        // Count the rows with T="RelLineTo"
                        if (type === 'RelLineTo') {
                          relLineToCount++;
                        }
                      }
                      // console.log(relLineToCount, ' get vertices length');
                      if (relLineToCount === 4) {
                        shapeType = 'rhombus';
                        perimeter = 'rhombusPerimeter';
                      }
                      if (relLineToCount === 3) {
                        shapeType = 'triangle';
                        perimeter = 'trianglePerimeter';
                      }
                      if (relLineToCount === 0) {
                        shapeType = 'rhombus';
                        perimeter = 'rhombusPerimeter';
                      }

                      width = width * shapescale; // Full width
                      height = height * shapescale; // Full height
                      // console.log(width, height, 'width and height');
                    }

                    // Check for rounded rectangles
                    if (row.getAttribute('T') === 'MoveTo' || row.getAttribute('T') === 'LineTo') {
                      const xValue = parseFloat(
                        row.getElementsByTagName('Cell')[0]?.getAttribute('V') || '0',
                      );
                      const yValue = parseFloat(
                        row.getElementsByTagName('Cell')[1]?.getAttribute('V') || '0',
                      );
                      width = Math.max(width, xValue * shapescale);
                      height = Math.max(height, yValue * shapescale);
                    }
                  }
                }
              }
              if (shape.getAttribute('Master') === '4') {
                shapeType = 'connector'; // Dynamic connector
              }
              // Determine style based on fill and border conditions
              let fillColor = '#00000000'; // Default fill color
              let strokeColor = '#00000000'; // Default stroke color
              const strokeWidth = 1; // Default stroke width

              const fillForegndCell = Array.from(shape.getElementsByTagName('Cell')).find(
                (cell) => cell.getAttribute('N') === 'FillForegnd',
              );
              // console.log(fillForegndCell, 'fillforegnd is');

              const linecolor = Array.from(shape.getElementsByTagName('Cell')).find(
                (cell) => cell.getAttribute('N') === 'LineColor',
              );
              if (fillForegndCell) {
                // console.log('FillForegnd is available:', fillForegndCell.getAttribute('V'));

                const newfillColor = fillForegndCell.getAttribute('V');
                if (newfillColor) fillColor = newfillColor;
              }
              if (linecolor) {
                const newStrokeColor = linecolor.getAttribute('V');
                if (newStrokeColor) strokeColor = newStrokeColor;
              }

              const style = `shape=${shapeType};rotation=${angle};rounded=${borderround};whiteSpace=wrap;html=1;shadow=0;comic=0;strokeWidth=${strokeWidth};strokeColor=${strokeColor};perimeter=${perimeter};fillColor=${fillColor};`;

              // console.log(style, 'style with perimiter');
              graph.setConnectable(true);
              graph.setAllowDanglingEdges(false);

              graph.setPortsEnabled(true);

              const vertex = graph.insertVertex(parent, id, node, x, y, width, height, style);

              if (id) vertices[id] = vertex; // Store the vertex with its ID for later use
            }
        });

        // const dynamicConnectionsMap = new Map<string, Set<string>>(); // Explicit typing
        const dynamicConnectionsMap = new Map<string, { toId: string; color: string }[]>();

        Array.from(connections).forEach((connection) => {
          const fromId = connection.getAttribute('FromSheet') as string;
          const toId = connection.getAttribute('ToSheet') as string;

          const fromShape = Array.from(shapes).find((shape) => shape.getAttribute('ID') === fromId);
          const toShape = Array.from(shapes).find((shape) => shape.getAttribute('ID') === toId);
          // console.log(vertices, 'new vertices');

          if (fromShape && toShape) {
            const findVertexByOriginalId = (
              originalId: string,
              vertices: Record<string, any>,
            ): any | null => {
              const key = Object.keys(vertices).find((k) =>
                k.startsWith(`drawing_tools_shape_${originalId}_`),
              );
              return key ? vertices[key] : null;
            };
            const findByOriginalId = (
              originalId: string,
              vertices: Record<string, any>,
            ): any | null => {
              const key = Object.keys(vertices).find((k) =>
                k.startsWith(`drawing_tools_shape_${originalId}_`),
              );
              return key ? key : null;
            };
            // const vertex = findVertexByOriginalId(originalId, vertices);

            const fromVertex = findVertexByOriginalId(fromId, vertices);
            const toVertex = findVertexByOriginalId(toId, vertices);
            // console.log(
            //   fromId,
            //   'fromid to shape-->',
            //   fromShape,
            //   toShape,
            //   'vertex-->',
            //   fromVertex,
            //   toVertex,
            //   'new id -->',
            //   findByOriginalId(fromId, vertices),
            // );

            if (fromVertex && toVertex) {
              const isDynamicConnector =
                fromShape.getAttribute('Master') === '4' || toShape.getAttribute('Master') === '4';

              if (isDynamicConnector) {
                // console.log(
                //   isDynamicConnector,
                //   'Dynamic connector detected between:',
                //   fromId,
                //   'and',
                //   toId,
                // );
                const fillForegndCell = Array.from(fromShape.getElementsByTagName('Cell')).find(
                  (cell) => cell.getAttribute('N') === 'FillForegnd',
                );
                let connectorcolor = '#000000';
                if (fillForegndCell) {
                  const cncolor = fillForegndCell.getAttribute('V');
                  if (cncolor) connectorcolor = cncolor;
                }
                // console.log(connectorcolor, 'fillforegnd is');

                const newfromid = findByOriginalId(fromId, vertices);
                const newtoid = findByOriginalId(toId, vertices);

                if (!dynamicConnectionsMap.has(newfromid)) {
                  dynamicConnectionsMap.set(newfromid, []);
                }
                // dynamicConnectionsMap.get(newfromid)?.add(newtoid);
                dynamicConnectionsMap.get(newfromid)?.push({
                  toId: newtoid,
                  color: connectorcolor,
                });
              }
            }
          }
        });

        // Create edges dynamically
        dynamicConnectionsMap.forEach((toIds) => {
          // const toIdArray = Array.from(toIds.toId) as string[]; // Ensure correct type
          // console.log(toIdArray, 'id array for cheking');

          for (let i = 0; i < toIds.length; i++) {
            for (let j = i + 1; j < toIds.length; j++) {
              const vertex1 = vertices[toIds[i].toId];
              const vertex2 = vertices[toIds[j].toId];
              const edgeStyle = `strokeColor=${toIds[i].color || 'black'};`;
              // console.log(edgeStyle, 'final connectin color');

              if (vertex1 && vertex2) {
                graph.insertEdge(parent, null, '', vertex1, vertex2, edgeStyle);
              } else {
                console.error(`Error: Unable to find vertices for ${toIds[i]} or ${toIds[j]}`);
              }
            }
          }
        });

        const verticesArray = Object.values(vertices); // Get all vertex objects
        graph.orderCells(false, verticesArray);
        // console.log(verticesArray, 'group data');
        if (verticesArray) graph.groupCells(null, 1, verticesArray as any);
        // const temp =
        //   'shape=ellipse;whiteSpace=wrap;html=1;aspect=fixed;rounded=1;shadow=0;comic=0;fontSize=12;strokeWidth=1;fillColor=#fff;perimeter=ellipsePerimeter;strokeColor=#000;';
        // graph.insertVertex(parent, '7894', '', 200, 200, 100, 100, temp);
      } finally {
        graph.getModel().endUpdate();
      }
      message.success('VSDX file imported successfully!');
    } catch (error) {
      console.error('Error importing VSDX file:', error);
      message.error('Failed to import VSDX file.');
    }
  };

  const copyHandler = async (values: { name: string; revision: boolean }) => {
    setIsOpenCopyModal(false);
    const { id } = await postCopyAssembly(values, defaultErrorHandler, String(assemblyId));
    message.success(`Assembly Copied Successfully`, 1).then(() => {
      navigate(`/assemblies/wizard/edit/${id}`);
      window.location.reload();
    });
  };

  const asBuiltHandler = async (values: { name: string; revision: boolean }) => {
    setIsOpenAsBuiltModal(false);
    const { id } = await postAsBuiltAssembly(values, defaultErrorHandler, String(assemblyId));
    message.success(`Set assembly as built is successfull`, 1).then(() => {
      navigate(`/assemblies/wizard/edit/${id}`);
      window.location.reload();
    });
  };

  const changeVisibilityHandler = async () => {
    setIsOpenVisibilityModal(false);
    const response = await putChangeVisibility(`${assemblyId}/change/visibility`);
    if (response.id) {
      dispatch(
        snackBarOpen(`Assembly set to ${response.is_public ? 'Public' : 'Private'} Visibility`),
      );
      setIsAssemblyVisibile(response.is_public);
      dispatch(wizardUpdateAssemblyVisibility(response.is_public));
    }
  };

  const handleDelete = async () => {
    Modal.confirm({
      title: 'Are you sure to delete this assembly?',
      content: 'Once deleted, the Assembly cannot be retrieved.',
      icon: <ExclamationCircleOutlined />,
      onOk: async () => {
        const result = await deleteAssembly(assemblyId);

        if (result) {
          message.success(`Assembly Deleted Successfully`);
          window.open(LINK_ASSEMBLY_NEW, '_self');
        }
      },
      okText: 'Yes',
      cancelText: 'No',
    });
  };

  const handleReset = () => {
    graph.getModel().clear();

    wizardResetDrawingData();

    message.success(`Assembly Reset Successfully!`);
  };

  const items: MenuProps['items'] = [
    {
      key: 'new',
      label: (
        <Link to={LINK_ASSEMBLY_NEW} reloadDocument>
          New
        </Link>
      ),
    },

    assemblyStatus.toLowerCase() === 'draft' || assemblyStatus.toLowerCase() === 'rejected'
      ? {
          key: 'save',
          label: (
            <WizardHeaderSaveAssembly assemblyForm={assemblyForm} buttonType="file" graph={graph} />
          ),
        }
      : null,
    {
      key: 'as-built',
      label: 'As Built',
      disabled: assemblyStatus.toLocaleLowerCase() !== 'published',
      onClick: () => setIsOpenAsBuiltModal(true),
    },
    isTenant
      ? {
          key: 'visibility',
          label: isAssemblyVisibile ? 'Make Private' : 'Make Public',
          disabled:
            assemblyStatus.toLocaleLowerCase() !== 'published' &&
            assemblyStatus.toLocaleLowerCase() !== 'as-built',
          onClick: () => setIsOpenVisibilityModal(true),
        }
      : null,
    {
      key: 'copy',
      label: 'Copy',
      disabled: !assemblyId || !isPaid,
      onClick: () => setIsOpenCopyModal(true),
    },
    {
      key: 'delete',
      label: 'Delete',
      disabled: !assemblyId || !isPaid || assemblyStatus === 'As Built',
      onClick: handleDelete,
    },
    {
      key: 'import',
      label: 'Import',
      onClick: () => fileInputRef.current?.click(),
    },
    {
      key: 'reset',
      label: 'Reset',
      onClick: handleReset,
    },
  ];

  return (
    <>
      <CopyModal
        isOpen={isOpenCopyModal}
        onCancel={() => setIsOpenCopyModal(false)}
        onConfirm={copyHandler}
        tenant={tenant}
      />
      <input
        type="file"
        accept=".vsdx"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={onFileChange}
      />
      <AsBuiltModal
        isOpen={isOpenAsBuiltModal}
        onCancel={() => setIsOpenAsBuiltModal(false)}
        onConfirm={asBuiltHandler}
      />
      <VisibilityModal
        isOpen={isOpenVisibilityModal}
        onCancel={() => setIsOpenVisibilityModal(false)}
        onConfirm={() => changeVisibilityHandler()}
      />
      <Dropdown menu={{ items }} trigger={['click']}>
        <Space>
          <MenuButton icon={<FolderOpenOutlined />} label="File" />
        </Space>
      </Dropdown>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    assemblyId: state.assemblyWizard.id,
    whoamiData: state.data.whoami.data,
    tenant: state.data.tenantConfig.data,
  };
};

const mapDispatchToProps = {
  wizardResetDrawingData,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuFile);
