/**
 * Graph index.ts
 * All graph configuration on here
 */

import { mxCell, mxUndoManager, StyleMap } from '@anekonnect/mxgraph';

import React, { useEffect, useCallback, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { ActionCreator } from 'redux';

import { setGraphSetting, loadGlobalSetting, createUndoManager } from './SetSettings';
import setGraphStyle from './SetStyles';
import { GraphProps, GraphModalsProps, GraphEventsProps } from './Types';
import Events from './Events';
import HandleControl from './Control';
import Actions from './Actions';
import Modals from './Modals';
import Draw from './Draw';
import BottomAppControl from './BottomAppControl';
import EventTemplate from './EventTemplate';
import KeyboardShortcuts from './KeyboardShortcuts';
import EventDelete from './EventDelete';
import {
  wizardAddHiddenComponent,
  wizardSetShowComponentControl,
  WizardUpdateComponentCableLength,
  wizardUpdateComponentCableLength,
  WizardUpdateComponentName,
  wizardUpdateComponentName,
} from '~/store/actions/wizard/Component';
import { mx } from '~/constants/wizard';
import { AppState } from '~/store/reducers';
import { Part } from '~/api/API';
import { JSX } from 'react/jsx-runtime';
import { useAppDispatch } from '~/store/hooks';
import SplitPinModal from '../Schematics/Connector/SplitModal';
import Connector from '../Schematics/Connector/Draw';
type BaseProps = {
  container: HTMLElement | undefined;
  children: React.ReactNode;
} & GraphProps &
  GraphModalsProps &
  GraphEventsProps;

type PropsFromState = {
  paperSize: string;
  parts: Part[] | undefined;
  assembly: any;
  options: any;
  shemeticconfig: any;
};

type PropsFromDispatch = {
  wizardUpdateComponentName: ActionCreator<WizardUpdateComponentName>;
  wizardUpdateComponentCableLength: ActionCreator<WizardUpdateComponentCableLength>;
  wizardAddHiddenComponent: typeof wizardAddHiddenComponent;
};

type Props = BaseProps & PropsFromState & PropsFromDispatch;

const Graph = ({
  graph,
  graphRefs,
  graphForms,
  graphSetVisibles,
  container,
  children,
  componentContainerModal,
  currentCell,
  setCurrentCell,
  wizardUpdateComponentName,
  wizardUpdateComponentCableLength,
  paperSize,
  wizardAddHiddenComponent,
  parts,
  assembly,
  // options,
  shemeticconfig,
}: Props) => {
  const dispatch = useAppDispatch();

  const [undoManager, setUndoManager] = useState<mxUndoManager>();

  const handleCloseComponentControl = useCallback(() => {
    dispatch(wizardSetShowComponentControl(false));
  }, [dispatch]);

  const configs = useCallback(() => {
    const { mxClient, mxUtils } = mx;

    if (!mxClient.isBrowserSupported) {
      mxUtils.error('Browser is not supported!', 200, false);
    } else {
      if (container) {
        setGraphSetting(mx, graph, paperSize);
        loadGlobalSetting(mx, graph);
        setGraphStyle(mx, graph);
        setUndoManager(createUndoManager(mx, graph));
      }
    }

    return function cleanup() {
      window.localStorage.removeItem('formCableData');
    };
  }, [graph, container, paperSize]);

  useEffect(configs, [configs]);

  const [isSplitModalVisible, setIsSplitModalVisible] = useState(false);
  // const [pinNumber, setPinNumber] = useState<number | null>(null);
  const [splitValue, setSplitValue] = useState<any>();
  const [parentid, setparentid] = useState<any>();
  const [labelname, setlabelname] = useState<any>();
  const [connetordata, setconnetordata] = useState<any>();
  const [schemconnetor, setschemconnetor] = useState<any>();
  const handleSplit = useCallback(() => {
    if (currentCell) {
      // Open the modal when the "Split" action is triggered
      setIsSplitModalVisible(true);
      handleCloseComponentControl();
    }
  }, [currentCell, handleCloseComponentControl]);

  const handleMerge = useCallback(() => {
    if (currentCell) {
      setSplitValue(undefined);

      setschemconnetor(shemeticconfig.connector[0].objects);
      graph.getModel().beginUpdate();
      try {
        const str = currentCell.id;
        const result = str.split('_container')[0]; // Splits the string and takes the first part
        setparentid(result);
        const idData = currentCell.id.split('_'); // structure id componentType_wizardType_componentId_componentSubId_furtherInformation
        const wizardTypeIndex = idData.findIndex((data) => data === 'ed' || data === 'schematics');
        const componentType = idData.slice(0, wizardTypeIndex).join('_');
        const componentId = idData[wizardTypeIndex + 1];
        const componentSubId = idData[wizardTypeIndex + 2];
        const { componentAlias } = graphForms.editComponentForm.getFieldsValue();
        setlabelname(componentAlias);
        // console.log(
        //   componentType,
        //   '||',
        //   componentId,
        //   '||',
        //   componentSubId,
        //   'assembly all rename id',
        // );
        const connectorData = assembly.connector[componentId];
        const deletecell: any = [];

        currentCell.parent.children.forEach((item: any, index) => {
          const idData = item.id.split('_'); // structure id componentType_wizardType_componentId_componentSubId_furtherInformation
          const wizardTypeIndex = idData.findIndex(
            (data: any) => data === 'ed' || data === 'schematics',
          );
          const type = idData.slice(0, wizardTypeIndex).join('_');
          const id = idData[wizardTypeIndex + 1];
          const subid = idData[wizardTypeIndex + 2];
          // console.log(type, id, subid, 'log data', graph);

          if (
            componentType === type &&
            componentId === id &&
            componentSubId === subid &&
            item.style === 'resizable=0;'
          ) {
            if (item.id !== currentCell.id) {
              // console.log(item.style, 'id not match', item);
              deletecell.push(item);
              // graph.removeCellsFromParent(item);
              // graph.fireEvent(new mxEventObject(mx.mxEvent.REMOVE_CELLS, 'cells', item));

              // graph.removeCells([item], true);
              // const evt = new mx.mxEventObject(mx.mxEvent.REMOVE_CELLS, 'cells', [item]);
              // graph.fireEvent(evt);
              // graph.removeCells([item]);
              // wizardRemoveComponent(id, subid, type);
              // deleteRelatedCells(graph, item);

              // graph.removeCells([item]);
              // graph.getModel().remove(item);
            } else {
              // console.log(item.style, ' match ', item);
            }
            // console.log(deletecell, 'delete cell array');
          }
          return (
            <>
              <div key={index}></div>
            </>
          );
        });
        deletecell.forEach((item: any) => {
          graph.getModel().remove(item);
        });

        graph.getModel().remove(currentCell);
        setSplitValue(0);
        // console.log(assembly, 'assembly', connectorData[componentSubId]);
        const newObj = {
          ...connectorData[componentSubId],
          index: Number(componentSubId),
          key: componentId + '_' + componentSubId,
          defaultAlias: connectorData[componentSubId].defaultAlias,
          lengthOfAllItems: connectorData[componentSubId].contacts,
          totalHeightOfContainer: connectorData[componentSubId].contacts * 30 + 20,
          hasShell: connectorData[componentSubId].has_shell,
          // options: options,
          options: {
            engineeringDrawing: {
              width: 240,
              height: 80,
              position: {
                x: 0,
                y: 0,
              },
              angle: 0,
              mirror: false,
            },
            schematics: {
              width: 40,
              height: 30,
              position: {
                x: 0,
                y: 0,
              },
              angle: 0,
              mirror: false,
            },
          },
          wizardType: connectorData[componentSubId].type,
          shapeFiles: [
            {
              url: connectorData[componentSubId].files[2].url,
              type: 'connector_face',
            },
            {
              url: connectorData[componentSubId].files[1].url,
              type: 'connector',
            },
          ],
          position: {
            engineeringDrawing: {
              x: 250,
              y: 90,
            },
            schematics: {
              x: currentCell.geometry.x,
              y: currentCell.geometry.y,
            },
          },
        };
        setconnetordata(newObj);

        // console.log('assembly state', newObj);
        handleCloseComponentControl();
        graph.refresh();
      } finally {
        graph.getModel().endUpdate();
      }
    }
  }, [
    currentCell,
    handleCloseComponentControl,
    assembly,
    graph,
    shemeticconfig,
    graphForms.editComponentForm,
  ]);
  const defaultStylesRef = useRef<StyleMap>();
  // console.log(connetordata, 'connector data', assembly, options, splitValue);
  // console.log('merge cell', splitValue);

  const handleSplitModalOk = useCallback(
    (enteredPinNumber: number) => {
      if (currentCell) {
        graph.getModel().remove(currentCell);
        setSplitValue(enteredPinNumber);
        setschemconnetor(shemeticconfig.connector[0].objects);
        graph.getModel().beginUpdate();
        try {
          //  graph.removeCells([currentCell.parent.children[2]])
          // console.log(
          //   currentCell,
          //   'Splitting pin for:',
          //   currentCell.id,
          //   'with pin number:',
          //   enteredPinNumber,
          // );

          // setparentid('connector_schematics_6242326678601728_0_draw');

          const { componentAlias } = graphForms.editComponentForm.getFieldsValue();
          setlabelname(componentAlias);
          // console.log('cell value', setlabelname);

          const str = currentCell.id;

          const result = str.split('_container')[0]; // Splits the string and takes the first part
          setparentid(result);
          const idData = currentCell.id.split('_'); // structure id componentType_wizardType_componentId_componentSubId_furtherInformation
          const wizardTypeIndex = idData.findIndex(
            (data) => data === 'ed' || data === 'schematics',
          );
          // const componentType = idData.slice(0, wizardTypeIndex).join('_');
          const componentId = idData[wizardTypeIndex + 1];
          const componentSubId = idData[wizardTypeIndex + 2];
          // console.log(
          //   componentType,
          //   '||',
          //   componentId,
          //   '||',
          //   componentSubId,
          //   'assembly all rename id',
          // );
          const connectorData = assembly.connector[componentId];
          // console.log(assembly, 'assembly', connectorData[componentSubId]);
          const newObj = {
            ...connectorData[componentSubId],
            index: Number(componentSubId),
            key: componentId + '_' + componentSubId,
            defaultAlias: connectorData[componentSubId].defaultAlias,
            lengthOfAllItems: connectorData[componentSubId].contacts,
            totalHeightOfContainer: connectorData[componentSubId].contacts * 30 + 20,
            hasShell: connectorData[componentSubId].has_shell,
            // options: options,
            options: {
              engineeringDrawing: {
                width: 240,
                height: 80,
                position: {
                  x: 0,
                  y: 0,
                },
                angle: 0,
                mirror: false,
              },
              schematics: {
                width: 40,
                height: 30,
                position: {
                  x: 0,
                  y: 0,
                },
                angle: 0,
                mirror: false,
              },
            },
            wizardType: connectorData[componentSubId].type,
            shapeFiles: [
              {
                url: connectorData[componentSubId].files[2].url,
                type: 'connector_face',
              },
              {
                url: connectorData[componentSubId].files[1].url,
                type: 'connector',
              },
            ],
            position: {
              engineeringDrawing: {
                x: 250,
                y: 90,
              },
              schematics: {
                x: currentCell.geometry.x,
                y: currentCell.geometry.y,
              },
            },
          };
          setconnetordata(newObj);

          // console.log('assembly state', newObj);

          setIsSplitModalVisible(false);
          graph.refresh();
        } finally {
          graph.getModel().endUpdate();
        }
      }
    },
    [currentCell, graph, assembly, shemeticconfig, graphForms.editComponentForm],
  );

  // console.log(shemeticconfig?.connector[0]?.objects,"config");

  const handleEditOK = useCallback(() => {
    const { componentAlias } = graphForms.editComponentForm.getFieldsValue();

    const { setModalEditVisible } = graphSetVisibles;

    if (currentCell) {
      graph.getModel().beginUpdate();

      try {
        const idData = currentCell.id.split('_'); // structure id componentType_wizardType_componentId_componentSubId_furtherInformation
        const wizardTypeIndex = idData.findIndex((data) => data === 'ed' || data === 'schematics');
        const componentType = idData.slice(0, wizardTypeIndex).join('_');
        const componentId = idData[wizardTypeIndex + 1];
        const componentSubId = idData[wizardTypeIndex + 2];
        const prefix = `${componentId}_${componentSubId}`;

        wizardUpdateComponentName(componentId, componentSubId, componentType, componentAlias);

        const parent = graph.getDefaultParent();
        const cells = graph.getChildCells(parent, true, false);

        if (componentType === 'cable') {
          const cableBezierID = `cable_ed_${componentId}_${componentSubId}_bezier_curve`;
          const bezierCurve = graph.getModel().getCell(cableBezierID);

          if (bezierCurve) {
            const value = bezierCurve.value;
            value.setAttribute('label', componentAlias);
            bezierCurve.setValue(value);
          }
        }

        cells.forEach((cell: mxCell) => {
          // console.log(cell, 'loop cell');

          const component = cell.id.includes(prefix);
          // console.log(component, 'component');

          if (component) {
            const value = cell.value;
            // console.log(value, 'check value', cell.value);

            value?.setAttribute('label', componentAlias);
            cell.setValue(value);
          }
        });
        // console.log(componentAlias, 'componentalies');

        currentCell.value.setAttribute('label', componentAlias);

        graph.refresh();

        const isBezier = currentCell.id.includes('bezier_curve');

        if (isBezier) {
          const {
            cableLength,
            cableTolerance,
            cableTickness,
            cableColor,
            showDimensionLine,
            cableUnit,
            toleranceUnit,
          } = graphForms.editComponentForm.getFieldsValue();

          const unitConverter = (value: number, unit: string) => {
            let convertedValue = value;
            if (value) {
              switch (unit) {
                case 'cm':
                  convertedValue = value * 10;
                  break;
                case 'meters':
                  convertedValue = value * 1000;
                  break;
                case 'in':
                  convertedValue = value * 25.4;
                  break;
                case 'ft':
                  convertedValue = value * 304.8;
                  break;
              }
            }

            return convertedValue;
          };

          const convertedCableLength = unitConverter(cableLength, cableUnit);

          if (convertedCableLength) {
            wizardUpdateComponentCableLength(
              componentId,
              componentSubId,
              componentType,
              convertedCableLength,
            );
          }

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const cableConfig = parts?.find((part) => part.name === 'cable');

          const bezierDimensionLineValue = `${cableLength || 3} ${cableUnit || 'mm'} +${
            cableTolerance || 0
          }/-0 ${toleranceUnit || 'mm'}`;

          const bezierDimensionLine = currentCell.id.replace('bezier_curve', 'dimension_line');
          const bezierDimensionLineCell = graph.getModel().getCell(bezierDimensionLine);

          if (bezierDimensionLineCell) {
            bezierDimensionLineCell.setValue(bezierDimensionLineValue);

            if (showDimensionLine || showDimensionLine === undefined) {
              bezierDimensionLineCell.setVisible(true);
            } else {
              bezierDimensionLineCell.setVisible(false);
            }
          }

          if (cableTickness) {
            graph.setCellStyles('strokeWidth', cableTickness, [currentCell]);
          }
          if (cableColor) {
            graph.setCellStyles('strokeColor', cableColor, [currentCell]);
          }

          const formCableData = JSON.parse(window.localStorage.getItem('formCableData') || '{}');
          formCableData[currentCell.id] = graphForms.editComponentForm.getFieldsValue();

          window.localStorage.setItem('formCableData', JSON.stringify(formCableData));
          graphForms.editComponentForm.resetFields();
        }

        graph.refresh();
      } finally {
        graph.getModel().endUpdate();
      }
    }

    // Close modal
    setModalEditVisible(false);
    handleCloseComponentControl();
  }, [
    graphForms.editComponentForm,
    graphSetVisibles,
    currentCell,
    handleCloseComponentControl,
    graph,
    wizardUpdateComponentName,
    parts,
    wizardUpdateComponentCableLength,
  ]);

  const handleEdit = useCallback(() => {
    graphSetVisibles.setModalEditVisible(true);
    handleCloseComponentControl();
  }, [graphSetVisibles, handleCloseComponentControl]);

  const handleMirror = useCallback(() => {
    graph.toggleCellStyle(mx.mxConstants.STYLE_FLIPH);
  }, [graph]);

  const handleOrderable = useCallback(
    (value: boolean) => {
      const { componentCellRef } = graphRefs.current;

      if (componentCellRef.current) {
        const container = componentCellRef.current.componentContainer;

        if (container) {
          graph.orderCells(value, [container]);
        }
      }
    },
    [graph, graphRefs],
  );

  const handleHideable = useCallback(() => {
    const { componentCellRef } = graphRefs.current;

    if (componentCellRef.current) {
      const container = componentCellRef.current.componentContainer;

      if (container) {
        container.setVisible(false);
        graph.setSelectionCells([]);
        graph.refresh();
        wizardAddHiddenComponent(container);
        handleCloseComponentControl();
      }
    }
  }, [graph, graphRefs, handleCloseComponentControl, wizardAddHiddenComponent]);

  const controlProps = {
    graph,
    graphSetVisibles,
  };

  const Control = useCallback(
    (props: JSX.IntrinsicAttributes & Pick<GraphProps, 'graphSetVisibles' | 'graph'>) => {
      return (
        <HandleControl {...props}>
          {({ deleteSelectionCell }) => {
            const actionsProps = {
              currentCell,
              graph,
              onDelete: deleteSelectionCell,
              onEdit: handleEdit,
              onMirror: handleMirror,
              onOrderFrontable: () => handleOrderable(false),
              onOrderBackable: () => handleOrderable(true),
              onHideable: handleHideable,
              onSplitt: handleSplit,
              onMerge: handleMerge,
            };

            return <Actions {...actionsProps} />;
          }}
        </HandleControl>
      );
    },
    [
      graph,
      currentCell,
      handleMerge,
      handleEdit,
      handleSplit,
      handleMirror,
      handleOrderable,
      handleHideable,
    ],
  );

  const eventsProps = {
    graph,
    graphRefs,
    graphForms,
    graphSetVisibles,
    currentCell,
    setCurrentCell,
  };

  const modalsProps = {
    currentCell,
    componentContainerModal,
    onOk: handleEditOK,
  };

  return (
    <React.Fragment>
      <Control {...controlProps} />
      <Events {...eventsProps} />
      <EventTemplate {...eventsProps} />
      <EventDelete graph={graph} />
      <Modals {...modalsProps} />

      <SplitPinModal
        visible={isSplitModalVisible}
        onOk={handleSplitModalOk}
        onCancel={() => setIsSplitModalVisible(false)}
      />
      {splitValue || splitValue === 0 ? (
        <Connector
          clog={'call from indexgraph'}
          graph={graph}
          parentid={parentid}
          labelname={labelname}
          data={connetordata}
          objects={schemconnetor}
          defaultStylesRef={defaultStylesRef}
          splitValue={splitValue}
        />
      ) : (
        ''
      )}

      {/* <Connector graph={graph} splitValue={splitValue} /> Pass splitValue to Connector */}

      <Draw graph={graph} />

      {children}
      <BottomAppControl graph={graph} undoManager={undoManager} />
      <KeyboardShortcuts graph={graph} undoManager={undoManager} />
    </React.Fragment>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    paperSize: state.assemblyWizard.paperSize,
    assembly: state.assemblyWizard.components,
    options: state.assemblyWizard.options,
    parts: state.data.tenantConfig.data?.parts,
    shemeticconfig: state.configs.schematics,
  };
};

const mapDispatchToProps = {
  wizardUpdateComponentName,
  wizardUpdateComponentCableLength,
  wizardAddHiddenComponent,
};

export default connect(mapStateToProps, mapDispatchToProps)(Graph);
