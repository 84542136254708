import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Modal } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '~/store/hooks';
import dayjs from 'dayjs';

import styles from './CopyModal.module.scss';

type Values = {
  name: string;
  revision: boolean;
};

type CopyButtonProps = {
  form: any;
  currentCopyKey: string;
  targetCopyKey: string;
};

const CopyButton = ({ form, currentCopyKey, targetCopyKey }: CopyButtonProps) => {
  const [isCopied, setIsCopied] = useState(false);

  const copy = () => {
    if (form.getFieldValue(currentCopyKey) === '-') {
      return;
    }

    form.setFieldsValue({ [targetCopyKey]: form.getFieldValue(currentCopyKey) });
    setIsCopied(true);
  };

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), 2000);
    }
  }, [isCopied]);

  return <Button icon={isCopied ? <CheckOutlined /> : <CopyOutlined />} onClick={copy} />;
};

type AsBuiltModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: (values: Values) => void;
};

export const AsBuiltModal = ({ isOpen, onCancel, onConfirm }: AsBuiltModalProps) => {
  const [form] = Form.useForm();
  const assemblyWizard = useAppSelector((state) => state.assemblyWizard);

  const defaultValues = useMemo(() => {
    return {
      currentAssemblyName: assemblyWizard.name,
      as_built_date: dayjs(),
    };
  }, [assemblyWizard]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue(defaultValues);
  }, [defaultValues, form]);

  return (
    <Modal
      forceRender
      cancelText="Cancel"
      okText="Confirm"
      title="As Built Assembly"
      open={isOpen}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            // Format the date before passing to onConfirm
            const formattedValues = {
              ...values,
              as_built_date: values.as_built_date.format('D MMM YYYY'),
            };
            onConfirm(formattedValues);
          })
          .catch((info) => console.log(info));
      }}
    >
      <Form form={form} labelCol={{ span: 10 }} layout="horizontal">
        <div className={styles.groupCopyButton}>
          <Form.Item
            className={styles.formInputCopy}
            label="Current Assembly Name"
            name="currentAssemblyName"
          >
            <Input disabled />
          </Form.Item>
          <CopyButton form={form} currentCopyKey="currentAssemblyName" targetCopyKey="name" />
        </div>
        <Form.Item
          label="New Assembly Name"
          name="name"
          rules={[{ required: true, message: 'Please input the assembly name' }]}
        >
          <Input placeholder="e.g. ROV to Camera" />
        </Form.Item>
        <Form.Item
          label="Date"
          name="as_built_date"
          rules={[{ required: true, message: 'Please input the date' }]}
        >
          <DatePicker
            style={{ width: '100%' }}
            format="D MMM YYYY"
            disabledDate={(current) => current && current < dayjs().subtract(10, 'day')}
          />
        </Form.Item>
        <Form.Item
          label="Initials"
          name="as_built_initials"
          rules={[{ required: true, message: 'Please input the initials' }]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
