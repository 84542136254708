import { mxGraph, mxGraphExportObject } from '@anekonnect/mxgraph';

const setWireSettings = (mx: mxGraphExportObject, graph: mxGraph) => {
  const {
    mxConstants,
    mxConnectionHandler,
    mxPoint,
    mxEvent,
    mxEdgeSegmentHandler,
    mxEdgeHandler,
    mxEdgeStyle,
    mxStyleRegistry,
    mxUtils,
    mxGraphView,
    mxGraphHandler,
    mxGuide,
  } = mx;

  // If connect preview is not moved away then getCellAt is used to detect the cell under
  // the mouse if the mouse is over the preview shape in IE (no event transparency), ie.
  // the built-in hit-detection of the HTML document will not be used in this case.
  mxConnectionHandler.prototype.movePreviewAway = false;
  mxConnectionHandler.prototype.waypointsEnabled = true;
  mx.mxGraph.prototype.resetEdgesOnConnect = false;
  mx.mxGraph.prototype.disconnectOnMove = false;
  mxConstants.SHADOWCOLOR = '#C0C0C0';

  // Enable alignment lines to help locate
  mxGraphHandler.prototype.guidesEnabled = true;

  // Alt disables guides
  mxGuide.prototype.isEnabledForEvent = function (evt: MouseEvent) {
    return !mxEvent.isAltDown(evt);
  };

  // Enables snapping waypoints to terminals
  mxEdgeHandler.prototype.snapToTerminals = true;

  graph.view.scale = 1;
  graph.setPanning(true);
  graph.setConnectable(true);
  graph.setConnectableEdges(true);
  graph.setDisconnectOnMove(false);
  graph.foldingEnabled = false;

  graph.panningHandler.usePopupTrigger = false;

  mxEdgeStyle.WireConnector = function (state, source, target, hints, result) {
    // Creates array of all way- and terminalpoints
    const pts = state.absolutePoints;
    let horizontal = true;
    let hint = null;

    // Gets the initial connection from the source terminal or edge
    if (source != null && state.view.graph.model.isEdge(source.cell)) {
      horizontal = state.style['sourceConstraint'] === 'horizontal';
    } else if (source != null) {
      horizontal = source.style['portConstraint'] !== 'vertical';

      // Checks the direction of the shape and rotates
      const direction = source.style[mxConstants.STYLE_DIRECTION];

      if (direction === 'north' || direction === 'south') {
        horizontal = !horizontal;
      }
    }

    // Adds the first point
    // TODO: Should move along connected segment
    let pt = pts[0];

    if (pt === null && source !== null) {
      pt = new mxPoint(state.view.getRoutingCenterX(source), state.view.getRoutingCenterY(source));
    } else if (pt !== null) {
      pt = pt.clone();
    }

    const first = pt;

    // Adds the waypoints
    if (hints !== null && hints.length > 0) {
      // FIXME: First segment not movable
      /*hint = state.view.transformControlPoint(state, hints[0]);
			mxLog.show();
			mxLog.debug(hints.length,'hints0.y='+hint.y, pt.y)
			
			if (horizontal && Math.floor(hint.y) != Math.floor(pt.y))
			{
				mxLog.show();
				mxLog.debug('add waypoint');
				pt = new mxPoint(pt.x, hint.y);
				result.push(pt);
				pt = pt.clone();
				//horizontal = !horizontal;
			}*/

      for (let i = 0; i < hints.length; i++) {
        horizontal = !horizontal;
        hint = state.view.transformControlPoint(state, hints[i]);

        if (horizontal) {
          if (pt.y !== hint.y) {
            pt.y = hint.y;
            result.push(pt.clone());
          }
        } else if (pt.x !== hint.x) {
          pt.x = hint.x;
          result.push(pt.clone());
        }
      }
    } else {
      hint = pt;
    }

    // Adds the last point
    pt = pts[pts.length - 1];

    // TODO: Should move along connected segment
    if (pt == null && target != null) {
      pt = new mxPoint(state.view.getRoutingCenterX(target), state.view.getRoutingCenterY(target));
    }

    if (horizontal) {
      if (pt.y !== hint.y && first.x !== pt.x) {
        result.push(new mxPoint(pt.x, hint.y));
      }
    } else if (pt.x !== hint.x && first.y !== pt.y) {
      result.push(new mxPoint(hint.x, pt.y));
    }
  };

  mxStyleRegistry.putValue('wireEdgeStyle', mxEdgeStyle.WireConnector);

  // This connector needs an mxEdgeSegmentHandler
  const mxGraphCreateHandler = mx.mxGraph.prototype.createHandler;
  mx.mxGraph.prototype.createHandler = function (state) {
    if (state != null) {
      if (this.model.isEdge(state.cell)) {
        const style = this.view.getEdgeStyle(state);

        if (style === mxEdgeStyle.WireConnector) {
          return new mxEdgeSegmentHandler(state);
        }
      }
    }

    return mxGraphCreateHandler.apply(this, [state]);
  };

  // Custom style for edge or wires
  // mxConstants.EDGE_SELECTION_COLOR = Constants.edgeSelectionColor;
  // mxConstants.EDGE_SELECTION_DASHED = false as typeof mxConstants.EDGE_SELECTION_DASHED;
  // mxConstants.EDGE_SELECTION_STROKEWIDTH = Constants.edgeSelectionStrokeWidth;

  // Connecting wires to wires
  // Overrides methods to preview and create new edges.
  // Sets source terminal point for edge-to-edge connections.
  mxConnectionHandler.prototype.createEdgeState = function (m) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type possible = any;

    // TODO: Type checking is doesn't match @typed-mxgraph, make your own types
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self: possible = this;

    const edge = self.graph.createEdge();
    const sourceConstraint = this.sourceConstraint;
    const prev = self.previous;
    const model = this.graph.model.isEdge(m.getCell());

    if (sourceConstraint && prev) {
      edge.style = `${mxConstants.STYLE_EXIT_X}=${sourceConstraint.point.x};${mxConstants.STYLE_EXIT_Y}=${sourceConstraint.point.y};strokeWidth=1;edgeStyle=EntityRelation;endArrow=classic;`;
    } else if (model) {
      const scale = this.graph.view.scale;
      const translate = this.graph.view.translate;
      const point = new mxPoint(
        this.graph.snap(m.getGraphX() / scale) - translate.x,
        this.graph.snap(m.getGraphY() / scale) - translate.y,
      );
      edge.geometry.setTerminalPoint(point, true);
    }

    return this.graph.view.createState(edge);
  };

  // Uses right mouse button to create edges on background (see also: lines 67 ff)
  mxConnectionHandler.prototype.isStopEvent = (m) => {
    // console.log('right mouse button press');

    return m.getState() || mxEvent.isRightMouseButton(m.getEvent());
  };
  // mxConnectionHandler.prototype.isStopEvent = function (m) {
  //   if (!m.getState() && mxEvent.isRightMouseButton(m.getEvent())) {
  //     return true;
  //   }

  //   return m.getState() || mxEvent.isRightMouseButton(m.getEvent());
  // };
  // Updates connection points before the routing is called.
  // Computes the position of edge to edge connection points.
  mxGraphView.prototype.updateFixedTerminalPoint = function (edge, terminal, source, constraint) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newEdge: any = edge;

    let point = null;

    if (constraint) {
      point = this.graph.getConnectionPoint(terminal, constraint);
    }

    if (source) {
      newEdge.sourceSegment = null;
    } else {
      newEdge.targetSegment = null;
    }

    if (point === null) {
      const scale = this.scale;
      const translate = this.translate;
      const origin = edge.origin;
      const geo = this.graph.getCellGeometry(edge.cell);
      point = geo.getTerminalPoint(source);

      if (point) {
        point = new mxPoint(
          scale * (translate.x + point.x + origin.x),
          scale * (translate.y + point.y + origin.y),
        );

        // Finds nearest segment on edge and computes intersection
        if (terminal && terminal.absolutePoints) {
          const seg = mxUtils.findNearestSegment(terminal, point.x, point.y);

          // Finds orientation of the segment
          const p0 = terminal.absolutePoints[seg];
          const pe = terminal.absolutePoints[seg + 1];
          const isHorizontal = p0.x - pe.x === 0;

          // Stores the segment in the edge state
          const key = source ? 'sourceConstraint' : 'targetConstraint';
          const value = isHorizontal ? 'horizontal' : 'vertical';
          edge.style[key] = value;

          // Keeps the coordinate within the segment bounds
          if (isHorizontal) {
            point.x = p0.x;
            point.y = Math.min(point.y, Math.max(p0.y, pe.y));
            point.y = Math.max(point.y, Math.min(p0.y, pe.y));
          } else {
            point.y = p0.y;
            point.x = Math.min(point.x, Math.max(p0.x, pe.x));
            point.x = Math.max(point.x, Math.min(p0.x, pe.x));
          }
        }
      }
      // Computes constraint connection points on vertices and ports
      else if (terminal && terminal.cell.geometry.relative) {
        point = new mxPoint(this.getRoutingCenterX(terminal), this.getRoutingCenterY(terminal));
      }
    }
    if (!edge.cell.target && edge.cell.id !== undefined) {
      if (edge.cell.target == null) {
        const model = this.graph.getModel();
        model.beginUpdate();
        try {
          const geo = edge.cell.geometry;
          if (geo && geo.targetPoint) {
            const parent = this.graph.getDefaultParent();

            // Create a dummy target vertex at the targetPoint location
            const dummyTarget = this.graph.insertVertex(
              parent,
              `${'hiddencell'}_terminate`, // Unique ID
              '', // No label
              geo.targetPoint.x,
              geo.targetPoint.y - 2,
              0,
              0, // Small size
              'opacity=0;fillColor=none;strokeColor=none;allowArrows=0;pointerEvents=0;recursiveResize=0;expand=0;editable=0;movable=0;locked=0;', // Make it invisible
            );
            // 'rounded=1;fillStyle=auto;strokeColor=red;labelBackgroundColor=none;fontSize=100;verticalAlign=top;horizontal=1;fontColor=red;rotation=-30;strokeWidth=10;fillColor=none;glass=0;fixedWidth=0;cloneable=0;deletable=0;rotatable=0;pointerEvents=0;resizable=0;connectable=0;allowArrows=0;recursiveResize=0;expand=0;editable=1;movable=0;locked=0;opacity=15;textOpacity=15;',

            // Assign the dummy target to the edge
            model.setTerminal(edge.cell, dummyTarget, false); // false -> it's the target

            // console.log(dummyTarget, 'Dummy target assigned at:', geo.targetPoint);
          }
        } finally {
          model.endUpdate();
        }
        this.graph.refresh(edge.cell);
      }
    }

    edge.setAbsoluteTerminalPoint(point, source);
  };

  //Updates target terminal point for edge-to-edge connections.
  const mxConnectionHandlerUpdateCurrentState = mxConnectionHandler.prototype.updateCurrentState;
  mxConnectionHandler.prototype.updateCurrentState = function (m, p) {
    // TODO: Type checking is doesn't match @typed-mxgraph, make your own types
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self: any = this;

    mxConnectionHandlerUpdateCurrentState.apply(self, [m, p]);

    if (self.edgeState) {
      self.edgeState.cell.geometry.setTerminalPoint(null as any, false);

      if (
        self.shape &&
        self.currentState &&
        self.currentState.view.graph.model.isEdge(self.currentState.cell)
      ) {
        const scale = self.graph.view.scale;
        const translate = self.graph.view.translate;
        const point = new mxPoint(
          self.graph.snap(m.getGraphX() / scale) - translate.x,
          m.getGraphY(),
        );

        self.edgeState.cell.geometry.setTerminalPoint(point, false);
      }
    }
  };

  // Updates the terminal and control points in the cloned preview.
  mxEdgeSegmentHandler.prototype.clonePreviewState = function (point, terminal) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type possible = any;

    // TODO: Type checking is doesn't match @typed-mxgraph, make your own types
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self: possible = this;

    const clone = mxEdgeHandler.prototype.clonePreviewState.apply(this, [point, terminal]);
    clone.cell = clone.cell.clone();

    const isSource = self.isSource;
    const isTarget = self.isTarget;

    if (isSource || isTarget) {
      clone.cell.geometry = clone.cell.geometry.clone();

      // Sets the terminal point of an edge if we're moving one of the endpoints
      if (this.graph.getModel().isEdge(clone.cell)) {
        // TODO: Only set this if the target or source terminal is an edge
        clone.cell.geometry.setTerminalPoint(point, isSource);
      } else {
        const zeroPoint = new mxPoint(0, 0);
        clone.cell.geometry.setTerminalPoint(zeroPoint, isSource);
      }
    }

    return clone;
  };

  const mxEdgeHandlerConnect = mxEdgeHandler.prototype.connect;
  mxEdgeHandler.prototype.connect = function (edge, terminal, isSource, isClone, me) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type possible = any;

    // TODO: Type checking is doesn't match @typed-mxgraph, make your own types
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self: possible = this;

    let result = null;
    const model = this.graph.getModel();

    model.beginUpdate();

    try {
      result = mxEdgeHandlerConnect.apply(this, [edge, terminal, isSource, isClone, me]);
      let geo = model.getGeometry(result);

      if (geo) {
        geo = geo.clone();
        let pt = null;

        if (model.isEdge(terminal)) {
          pt = self.abspoints[self.isSource ? 0 : self.abspoints.length - 1];
          pt.x = pt.x / this.graph.view.scale - this.graph.view.translate.x;
          pt.y = pt.y / this.graph.view.scale - this.graph.view.translate.y;

          const pstate = this.graph.getView().getState(this.graph.getModel().getParent(edge));

          if (pstate) {
            pt.x -= pstate.origin.x;
            pt.y -= pstate.origin.y;
          }

          pt.x -= this.graph.panDx / this.graph.view.scale;
          pt.y -= this.graph.panDy / this.graph.view.scale;
        }

        if (pt) {
          geo.setTerminalPoint(pt, isSource);
          model.setGeometry(edge, geo);
        }
      }
    } finally {
      model.endUpdate();
    }

    return result;
  };

  // Adds in-place highlighting for complete cell area (no hotspot).
  const mxConnectionHandlerCreateMarker = mxConnectionHandler.prototype.createMarker;
  mxConnectionHandler.prototype.createMarker = function () {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type possible = any;

    // TODO: Type checking is doesn't match @typed-mxgraph, make your own types
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self: possible = this;

    const marker: possible = mxConnectionHandlerCreateMarker.apply(this);

    // Uses complete area of cell for new connections (no hotspot)
    marker.intersects = () => {
      return true;
    };

    // Adds in-place highlighting
    // const mxCellHighlightHighlight = mxCellHighlight.prototype.highlight
    if (marker.highlight) {
      marker.highlight.highlight = function (state: possible) {
        if (self.state !== state) {
          if (self.state) {
            self.state.style = self.lastStyle;

            // Workaround for shape using current stroke width if no strokewidth defined
            self.state.style['strokeWidth'] = self.state.style['strokeWidth'] || '1';
            self.state.style['strokeColor'] = self.state.style['strokeColor'] || 'none';

            if (self.state.shape) {
              self.state.view.graph.cellRenderer.configureShape(self.state);
              self.state.shape.redraw();
            }
          }

          if (state) {
            self.lastStyle = state.style;
            state.style = mxUtils.clone(state.style);
            state.style['strokeColor'] = '#00ff00';
            state.style['strokeWidth'] = '3';

            if (state.shape) {
              state.view.graph.cellRenderer.configureShape(state);
              state.shape.redraw();
            }
          }

          self.state = state;
        }
      };
    }

    return marker;
  };

  const mxEdgeHandlerCreateMarker = mxEdgeHandler.prototype.createMarker;
  mxEdgeHandler.prototype.createMarker = function () {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type possible = any;

    const marker: possible = mxEdgeHandlerCreateMarker.apply(this);

    // Adds in-place highlighting when reconnecting existing edges
    marker.highlight.highlight = this.graph.connectionHandler.marker.highlight.highlight;

    return marker;
  };

  // Adds oval markers for edge-to-edge connections.
  const mxGraphGetCellStyle = mx.mxGraph.prototype.getCellStyle;
  mx.mxGraph.prototype.getCellStyle = function (cell) {
    let style = null;

    if (graph && graph.isEnabled()) {
      const model = graph.getModel();

      if (model) {
        style = mxGraphGetCellStyle.apply(this, [cell]);
        const isEdge = graph?.model.isEdge(cell);

        if (style && isEdge) {
          style = mxUtils.clone(style);

          const isEdgeTerm = (v: boolean) => graph?.model.isEdge(graph.model.getTerminal(cell, v));

          if (isEdgeTerm(true)) {
            style['startArrow'] = 'oval';
          }

          if (isEdgeTerm(false)) {
            style['endArrow'] = 'oval';
          }
        }
      }
    }

    return style;
  };
};

export default setWireSettings;
