/* Do not change, this code is generated from Golang structs */

import { Config } from '~/store/reducers/configs';

export class UIConfig {
  connector_library_display_name!: string;
  demo_only!: boolean;
  display_name!: string;
  favicon!: string;
  front_page_widgets!: string[];
  landing_page!: string;
  authentication_required_pages!: string[];
  has_wizard_banner!: boolean;
  hide_connectors_banner!: boolean;
  logo_path!: string;
  logo_url!: string;
  template_url!: string;
  primary_color!: string;
  primary_inverse_color!: string;
  wizard_banner_background!: string;
  wizard_banner_height!: number;
  wizard_banner_text!: string;
  override_header_logged_in!: boolean;
  override_header_logged_out!: boolean;
  override_footer_logged_in!: boolean;
  override_footer_logged_out!: boolean;
  connectors_page_size!: number;
  assemblies_page_size!: number;
  cables_page_size!: number;
  assembly_status_mapping!: {
    [key: string]: string;
  };

  static createFrom(source: any) {
    if (typeof source === 'string') source = JSON.parse(source);
    const result = new UIConfig();
    result.connector_library_display_name = source['connector_library_display_name'];
    result.demo_only = source['demo_only'];
    result.display_name = source['display_name'];
    result.favicon = source['favicon'];
    result.front_page_widgets = source['front_page_widgets'];
    result.landing_page = source['landing_page'];
    result.authentication_required_pages = source['authentication_required_pages'];
    result.has_wizard_banner = source['has_wizard_banner'];
    result.hide_connectors_banner = source['hide_connectors_banner'];
    result.logo_path = source['logo_path'];
    result.logo_url = source['logo_url'];
    result.template_url = source['template_url'];
    result.primary_color = source['primary_color'];
    result.primary_inverse_color = source['primary_inverse_color'];
    result.wizard_banner_background = source['wizard_banner_background'];
    result.wizard_banner_height = source['wizard_banner_height'];
    result.wizard_banner_text = source['wizard_banner_text'];
    result.override_header_logged_in = source['override_header_logged_in'];
    result.override_header_logged_out = source['override_header_logged_out'];
    result.override_footer_logged_in = source['override_footer_logged_in'];
    result.override_footer_logged_out = source['override_footer_logged_out'];
    result.connectors_page_size = source['connectors_page_size'];
    result.assemblies_page_size = source['assemblies_page_size'];
    result.cables_page_size = source['cables_page_size'];
    result.assembly_status_mapping = source['assembly_status_mapping'];

    return result;
  }

  //[UIConfig:]

  //[end]
}
export class Filter {
  field!: string;
  display_name!: string;
  key!: string;

  static createFrom(source: any): Filter {
    if (typeof source === 'string') {
      source = JSON.parse(source);
    }

    const result = new Filter();
    result.field = source['field'];
    result.display_name = source['display_name'];
    result.key = source['key'];

    return result;
  }
}

export class MultipleFilter {
  name!: string;
  key!: string;
  filters!: Filters;

  static createFrom(source: any): MultipleFilter {
    if (typeof source === 'string') source = JSON.parse(source);
    const result = new MultipleFilter();
    result.name = source['name'];
    result.key = source['key'];
    result.filters = Filters.createFrom(source['filters']);

    return result;
  }
}

export class Filters {
  select?: Filter[];
  numeric?: Filter[];
  multiple?: MultipleFilter[];

  static createFrom(source: any): Filters {
    if (typeof source === 'string') source = JSON.parse(source);
    const result = new Filters();
    result.select = source['select']
      ? source['select'].map(function (element: any) {
          return Filter.createFrom(element);
        })
      : undefined;
    result.numeric = source['numeric']
      ? source['numeric'].map(function (element: any) {
          return Filter.createFrom(element);
        })
      : undefined;
    result.multiple = source['multiple']
      ? source['multiple'].map(function (element: any) {
          return MultipleFilter.createFrom(element);
        })
      : undefined;

    return result;
  }

  //[Filters:]

  //[end]
}

export class FileType {
  name!: string;
  mime_type!: string;
  display_name!: string;

  static createFrom(source: any) {
    if (typeof source === 'string') source = JSON.parse(source);
    const result = new FileType();
    result.name = source['name'];
    result.mime_type = source['mime_type'];
    result.display_name = source['display_name'];

    return result;
  }

  //[FileType:]

  //[end]
}
export class Prefix {
  title!: string;
  value!: string[];
  display_name!: string;

  static createFrom(source: any) {
    if (typeof source === 'string') source = JSON.parse(source);
    const result = new Prefix();
    result.title = source['title'];
    result.value = source['enum'];
    result.display_name = source['enumNames'];

    return result;
  }

  //[FileType:]

  //[end]
}
export class Part {
  name!: string;
  can_bookmark!: boolean;
  can_copy!: boolean;
  can_delete!: boolean;
  can_edit!: boolean;
  can_share!: boolean;
  display_name!: string;
  file_types!: FileType[];
  card_display_types!: string[];
  read_display_types!: string[];
  filters?: Filters;
  card_schema!: string;
  read_schema!: string;
  write_schema!: string;
  form_ui_schema!: string;
  engineering_drawing_configs!: Config[];
  schematics_configs!: Config[];
  units: string | undefined;

  static createFrom(source: any) {
    if (typeof source === 'string') source = JSON.parse(source);
    const result = new Part();
    result.name = source['name'];
    result.can_bookmark = source['can_bookmark'];
    result.can_copy = source['can_copy'];
    result.can_delete = source['can_delete'];
    result.can_edit = source['can_edit'];
    result.can_share = source['can_share'];
    result.display_name = source['display_name'];
    result.file_types = source['file_types']
      ? source['file_types'].map(function (element: any) {
          return FileType.createFrom(element);
        })
      : null;
    result.card_display_types = source['card_display_types'];
    result.read_display_types = source['read_display_types'];
    result.filters = source['filters'] ? Filters.createFrom(source['filters']) : undefined;
    result.card_schema = source['card_schema'];
    result.read_schema = source['read_schema'];
    result.write_schema = source['write_schema'];
    result.form_ui_schema = source['form_ui_schema'];
    result.engineering_drawing_configs = source['engineering_drawing_configs'];
    result.schematics_configs = source['schematics_configs'];
    result.units = source['units'];

    return result;
  }

  //[Part:]

  //[end]
}
export class NavigationNode {
  name!: string;
  type!: string;
  when_parent_value?: string;
  nodes?: NavigationNode[];

  static createFrom(source: any) {
    if (typeof source === 'string') source = JSON.parse(source);
    const result = new NavigationNode();
    result.name = source['name'];
    result.type = source['type'];
    result.when_parent_value = source['when_parent_value'];
    result.nodes = source['nodes']
      ? source['nodes'].map(function (element: any) {
          return NavigationNode.createFrom(element);
        })
      : null;

    return result;
  }

  //[NavigationNode:]

  //[end]
}
export class Features {
  bookmark_cable!: boolean;
  bookmark_connector!: boolean;
  bookmark_part!: boolean;
  share_cable!: boolean;
  share_connector!: boolean;
  share_part!: boolean;

  static createFrom(source: any) {
    if (typeof source === 'string') source = JSON.parse(source);
    const result = new Features();
    result.bookmark_cable = source['bookmark_cable'];
    result.bookmark_connector = source['bookmark_connector'];
    result.bookmark_part = source['bookmark_part'];
    result.share_cable = source['share_cable'];
    result.share_connector = source['share_connector'];
    result.share_part = source['share_part'];

    return result;
  }

  //[Features:]

  //[end]
}
export class TenantConfig {
  allowed_domains_for_user_creation!: string[];
  auth_system!: string;
  revisions_table_config_input!: string;
  revisions_table_config_display!: string;
  customer_approvals_config_input!: string;
  customer_approvals_config_display!: string;
  bill_of_materials_table_config_input!: string;
  bill_of_materials_table_config_display!: string;
  assembly_specifications!: string;
  cable_card_schema!: string;
  cable_details_schema!: string;
  cable_schema!: string;
  connector_card_schema!: string;
  connector_details_schema!: string;
  connector_schema!: string;
  features?: Features;
  ga!: string;
  home_url!: string;
  is_parts_available!: boolean;
  is_auto_drawing_number!: boolean;
  is_drawing_prefix!: boolean;
  is_drawing_suffix!: boolean;
  prefix?: Prefix;
  suffix?: Prefix;
  locked!: boolean;
  name!: string;
  support_email!: string;
  navigation!: NavigationNode[];
  parts!: Part[];
  request_quote_logged_in!: string;
  request_quote_logged_out!: string;
  review_modeling_request!: boolean;
  site_url!: string;
  supported_manufacturers!: string[];
  title_block_data_schema!: string;
  ui_config?: UIConfig;
  user_schema!: string;
  user_display_schema!: string;
  admin_schema!: string;
  admin_display_schema!: string;
  series!: string[];
  template_config!: string;
  cable_test_card_schema!: string;
  cable_test_card_ui_schema!: string;
  approval_history_table_config_display!: string;

  static createFrom(source: any) {
    if (typeof source === 'string') source = JSON.parse(source);
    const result = new TenantConfig();
    result.allowed_domains_for_user_creation = source['allowed_domains_for_user_creation'];
    result.auth_system = source['auth_system'];
    result.revisions_table_config_input = source['revisions_table_config_input'];
    result.revisions_table_config_display = source['revisions_table_config_display'];
    result.customer_approvals_config_input = source['customer_approvals_config_input'];
    result.customer_approvals_config_display = source['customer_approvals_config_display'];
    result.bill_of_materials_table_config_input = source['bill_of_materials_table_config_input'];
    result.bill_of_materials_table_config_display =
      source['bill_of_materials_table_config_display'];
    result.assembly_specifications = source['assembly_specifications'];
    result.cable_card_schema = source['cable_card_schema'];
    result.cable_details_schema = source['cable_details_schema'];
    result.cable_schema = source['cable_schema'];
    result.connector_card_schema = source['connector_card_schema'];
    result.connector_details_schema = source['connector_details_schema'];
    result.connector_schema = source['connector_schema'];
    result.features = source['features'] ? Features.createFrom(source['features']) : undefined;
    result.ga = source['ga'];
    result.home_url = source['home_url'];
    result.is_parts_available = source['is_parts_available'];
    result.is_auto_drawing_number = source['is_auto_drawing_number'];
    result.is_drawing_prefix = source['is_drawing_prefix'];
    result.is_drawing_suffix = source['is_drawing_suffix'];
    result.prefix = source['prefix'] ? Prefix.createFrom(source['prefix']) : undefined; // Fix: Single object
    result.suffix = source['suffix'] ? Prefix.createFrom(source['suffix']) : undefined; // Fix: Added for suffix
    result.locked = source['locked'];
    result.name = source['name'];
    result.support_email = source['support_email'];
    result.navigation = source['navigation']
      ? source['navigation'].map(function (element: any) {
          return NavigationNode.createFrom(element);
        })
      : null;
    result.parts = source['parts']
      ? source['parts'].map(function (element: any) {
          return Part.createFrom(element);
        })
      : null;
    result.request_quote_logged_in = source['request_quote_logged_in'];
    result.request_quote_logged_out = source['request_quote_logged_out'];
    result.review_modeling_request = source['review_modeling_request'];
    result.site_url = source['site_url'];
    result.supported_manufacturers = source['supported_manufacturers'];
    result.title_block_data_schema = source['title_block_data_schema'];
    result.ui_config = source['ui_config'] ? UIConfig.createFrom(source['ui_config']) : undefined;
    result.user_schema = source['user_schema'];
    result.user_display_schema = source['user_display_schema'];
    result.admin_schema = source['admin_schema'];
    result.admin_display_schema = source['admin_display_schema'];
    result.series = source['series'];
    result.template_config = source['template_config'];
    result.cable_test_card_schema = source['cable_test_card_schema'];
    result.cable_test_card_ui_schema = source['cable_test_card_ui_schema'];
    result.approval_history_table_config_display = source['approval_history_table_config_display'];

    return result;
  }

  //[TenantConfig:]

  //[end]
}
export class Time {
  static createFrom(source: any) {
    if (typeof source === 'string') source = JSON.parse(source);
    const result = new Time();

    return result;
  }

  //[Time:]

  //[end]
}
export class Tag {
  id!: number;
  name!: string;
  tenant!: string;
  color!: string;
  parent_tag!: number;
  visibility!: string;
  created_at?: Time;
  created_by!: number;

  static createFrom(source: any) {
    if (typeof source === 'string') source = JSON.parse(source);
    const result = new Tag();
    result.id = source['id'];
    result.name = source['name'];
    result.tenant = source['tenant'];
    result.color = source['color'];
    result.parent_tag = source['parent_tag'];
    result.visibility = source['visibility'];
    result.created_at = source['created_at'] ? Time.createFrom(source['created_at']) : undefined;
    result.created_by = source['created_by'];

    return result;
  }

  //[Tag:]

  //[end]
}
