import { FilterFilled, SearchOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, Tag, Modal, message, InputRef } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
  memo,
  Key,
  ChangeEvent,
  MouseEvent,
} from 'react';
import formatDate from 'date-fns/format';
import { Link } from 'react-router-dom';

import style from './Assemblies.module.scss';

import { PartItem } from '~/types/Parts';
import { getTags } from '~/api/AuthorizedGets';
import { Tag as TagProps } from '~/api/API';
import { deleteAssembly } from '~/api/AuthorizedDeletes';
import { useAppSelector } from '~/store/hooks';
import { putUpdateRequestReview, putUpdateStatus } from '~/api/AuthorizedPuts';

type FilterConfirmProps = {
  closeDropdown: boolean;
};

type Columns = {
  title: string;
  dataIndex: string;
  key: string;
  sorter?: (a: PartItem, b: PartItem) => number;
  sortDirections?: ['descend', 'ascend'];
  [k: string]: any;
};

const sorterBy = (key: string): Pick<Columns, 'sorter' | 'sortDirections'> => ({
  sorter: (a: PartItem, b: PartItem) => String(a[key]).localeCompare(b[key]),
  sortDirections: ['descend', 'ascend'],
});

type AssembliesTableProps = {
  category: string;
  dataSource: PartItem[] | undefined;
  isLoading: boolean;
  handleServerSearch: (key: Key, column: string) => void;
  handleServerSort: (field: string, order: string) => void;
};

const AssembliesTable = ({
  category,
  dataSource,
  isLoading,
  handleServerSearch,
  handleServerSort,
}: AssembliesTableProps) => {
  const userId = useAppSelector((state) => state.data.whoami.data?.id);
  const tenantConfig = useAppSelector((state) => state.data.tenantConfig.data);
  const UIConfig = tenantConfig?.ui_config;

  const [listTags, setListTags] = useState<TagProps[]>([]);
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [rejectLoading, setRejectLoading] = useState<boolean>(false);
  const [obsoleteLoading, setobsoleteLoading] = useState<boolean>(false);

  const getListTags = () => {
    getTags().then((res: TagProps[]) => {
      setListTags(res);
    });
  };

  useEffect(getListTags, []);

  const searchInput = useRef<InputRef>(null);

  const handleSearch = useCallback(
    (selectedKeys: Key[], confirm: (param?: FilterConfirmProps) => void, dataIndex: string) => {
      confirm();
      handleServerSearch(selectedKeys[0], dataIndex);
    },
    [handleServerSearch],
  );

  const handleReset = useCallback(
    (
      clearFilters: (() => void) | undefined,
      confirm: (param?: FilterConfirmProps) => void,
      column: string,
    ) => {
      clearFilters && clearFilters();
      confirm();
      handleServerSearch('', column);
    },
    [handleServerSearch],
  );

  const getColumnSearchProps = useCallback(
    (dataIndex: string, title: string) => ({
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: FilterDropdownProps) => {
        const onChange = (e: ChangeEvent<HTMLInputElement>) => {
          setSelectedKeys(e.target.value ? [e.target.value] : []);
        };

        return (
          <div style={{ padding: '8px' }}>
            <Input
              ref={searchInput}
              placeholder={`Search ${title}`}
              style={{ marginBottom: 8, display: 'block' }}
              value={selectedKeys[0]}
              onChange={onChange}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            />

            <Space>
              <Button
                size="small"
                style={{ width: 90 }}
                onClick={() => handleReset(clearFilters, confirm, dataIndex)}
                disabled={!selectedKeys.length}
              >
                Reset
              </Button>
              <Button
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
              >
                Search
              </Button>
            </Space>
          </div>
        );
      },
      filterIcon: (filtered: boolean) => {
        const isStatus = dataIndex === 'status';

        if (isStatus) return <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />;

        return <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />;
      },
      onFilter: (value: string, record: Record<string, any>) => {
        if (dataIndex === 'tags') {
          const data = record[dataIndex];

          if (data) {
            let found = false;
            data.forEach((element: number) => {
              const tag = listTags.find((tag) => tag.id === element);

              if (tag) {
                if (tag.name.toString().toLowerCase().includes(value.toLowerCase())) {
                  found = true;

                  return;
                }
              }
            });

            return found;
          }
        }

        return record[dataIndex]
          ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          : '';
      },
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          setTimeout(() => {
            if (searchInput && searchInput.current) {
              searchInput.current.select();
            }
          }, 100);
        }
      },
      render: (text: string) => text,
    }),
    [handleSearch, handleReset, listTags],
  );

  const handleLinkClick = (e: MouseEvent<HTMLAnchorElement>, url: string) => {
    if (e.metaKey || e.ctrlKey) {
      // Open in new tab if Cmd (Mac) or Ctrl (Windows/Linux) is pressed
      window.open(url, '_blank');
    } else {
      // Otherwise, navigate to the URL and perform a hard reload
      window.location.href = url;
    }
  };

  const columns: Columns[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...sorterBy('name'),
      ...getColumnSearchProps('name', 'Name'),
      render: (text: string, record: PartItem) => {
        const url = `/assemblies/wizard/edit/${record.id}`;

        return {
          children: (
            <Link
              to={url}
              onClick={(e: MouseEvent<HTMLAnchorElement>) => {
                e.preventDefault();
                handleLinkClick(e, url);
              }}
            >
              <Button size="small" type="link">
                {text}
              </Button>
            </Link>
          ),
          props: {},
        };
      },
    },
    {
      title: 'Drawing Number',
      dataIndex: 'user_drawing_number',
      key: 'user_drawing_number',
      ...sorterBy('user_drawing_number'),
      ...getColumnSearchProps('user_drawing_number', 'Drawing Number'),
      render: (text: string, record: PartItem) => {
        return {
          children: `${record.drawingnumberdata.prefix}${text}${record.drawingnumberdata.suffix}`,
        };
      },
    },
    {
      title: 'Drawing Revision',
      dataIndex: 'user_drawing_revision',
      key: 'user_drawing_revision',
      ...sorterBy('user_drawing_revision'),
      ...getColumnSearchProps('user_drawing_revision', 'Drawing Revision'),
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      key: 'tags',
      ...getColumnSearchProps('tags', 'Tags'),
      render: (tags: number[]) => {
        if (tags) {
          return {
            children: (
              <>
                {tags.map((tag) => {
                  const tagElem = listTags.find((t) => t.id === tag);

                  if (tagElem) {
                    return (
                      <Tag
                        key={tagElem.name}
                        color={tagElem.color}
                        style={{
                          margin: '0 4px',
                        }}
                      >
                        {tagElem.name}
                      </Tag>
                    );
                  }

                  return null;
                })}
              </>
            ),
          };
        }
      },
    },
    {
      title: 'Designed By',
      dataIndex: 'created_by_name',
      key: 'designed_by',
      ...getColumnSearchProps('created_by_name', 'Designed By'),
    },
    {
      title: 'Last Update',
      dataIndex: 'updated_at',
      key: 'last_update',
      ...sorterBy('updated_at'),
      render: (v: string) => formatDate(new Date(v), 'PP'),
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'create_at',
      ...sorterBy('created_at'),
      render: (v: string) => formatDate(new Date(v), 'PP'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ...sorterBy('status'),
      render: (status: string) => {
        return UIConfig?.assembly_status_mapping
          ? UIConfig?.assembly_status_mapping[status] || status
          : status;
      },
    },
    {
      title: 'Action',
      key: 'action',
      dataIndex: 'action',
      align: 'center',
      render: (_: any, record: PartItem) => {
        const confirm = () => {
          Modal.confirm({
            title: `Are you sure you want to delete "${record.name}" assembly? `,
            content: 'Once deleted, the Assembly cannot be retrieved.',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            cancelText: 'No',
            onOk: async () => {
              const result = await deleteAssembly(record.id);

              if (result) {
                message.success(`Assembly deleted successfully!`);
                setTimeout(() => window.location.reload(), 900);
              }
            },
          });
        };

        const approveHandler = async () => {
          try {
            setApproveLoading(true);
            const response = await putUpdateRequestReview(`${record.id}/review/approve`, {
              reviewers: [userId],
            });

            if (response) {
              message.success('Assembly approved');

              // reload with delay
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }

            setApproveLoading(false);
          } catch (error) {
            setApproveLoading(false);
            message.error('Something went wrong');
          }
        };
        const ObsoleteHandler = async () => {
          try {
            setobsoleteLoading(true);
            const response = await putUpdateStatus(`${record.id}/update-status`, {
              status: 'Obsolete',
            });

            if (response) {
              message.success('Assembly  Obsoleted');

              // reload with delay
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }

            setobsoleteLoading(false);
          } catch (error) {
            setobsoleteLoading(false);
            message.error('Something went wrong');
          }
        };

        const rejectHandler = async () => {
          try {
            setRejectLoading(true);
            const response = await putUpdateRequestReview(`${record.id}/review/reject`, {
              reviewers: [userId],
            });

            if (response) {
              message.success('Assembly rejected');

              // reload with delay
              setTimeout(() => {
                window.location.reload();
              }, 500);
            }

            setRejectLoading(false);
          } catch (error) {
            setRejectLoading(false);
            message.error('Something went wrong');
          }
        };

        return (
          <Space size="middle">
            {category === 'review-requested' ? (
              <Space size="middle" align="center">
                <Button type="primary" danger onClick={rejectHandler} loading={rejectLoading}>
                  Reject
                </Button>
                <Button type="primary" onClick={approveHandler} loading={approveLoading}>
                  Approve
                </Button>
              </Space>
            ) : record.status === 'Published' ? (
              <Button type="primary" danger onClick={ObsoleteHandler} loading={obsoleteLoading}>
                Obsolete
              </Button>
            ) : record.status === 'Obsolete' ? (
              ''
            ) : (
              <Button disabled={record.status === 'As Built' ? true : false} onClick={confirm}>
                Delete
              </Button>
            )}
          </Space>
        );
      },
    },
  ];

  function onChange(_pagination: any, _filters: any, sorter: any) {
    handleServerSort(sorter.field, sorter.order);
  }

  return (
    <Table
      columns={columns}
      className={style.table_content}
      dataSource={dataSource}
      loading={!dataSource || isLoading}
      pagination={false}
      rowKey="id"
      onChange={onChange}
    />
  );
};

export default memo(AssembliesTable);
