import { Button, Input, InputNumber, message, Space, Tag, Tooltip } from 'antd';
import Collapse from 'antd/lib/collapse';
import React, { useEffect, useState } from 'react';
import { mxCell, mxGraph } from '@anekonnect/mxgraph';
import { connect } from 'react-redux';
import _ from 'lodash';

import { replaceStyles } from '../../Helper';

import { mx } from '~/constants/wizard';
import PopoverPicker from '~/components/PopoverPicker';
import {
  wizardSetNewTemplatePosition,
  wizardSetNewTitleBlockStyle,
  wizardSetTitleBlockData,
  wizardUpdatePaperSizeData,
} from '~/store/actions/wizard/Action';
import { AppState } from '~/store/reducers';
import { PaperSizeData, TitleBlockGeometry } from '~/utils/paperSize';
import { putUpdateTemplateSettings } from '~/api/AuthorizedPuts';
import { TitleBlockData } from '~/store/reducers/wizard/State';

const { Panel } = Collapse;

type PropsFromState = {
  paperSize: string;
  paperSizeData: PaperSizeData;
  titleBlockData: TitleBlockData[];
  isViewOnly: boolean;
  whoamiData: {
    payment_status?: string;
    type?: string;
  };
};

type PropsFromDispatch = {
  wizardUpdatePaperSizeData: typeof wizardUpdatePaperSizeData;
  wizardSetTitleBlockData: typeof wizardSetTitleBlockData;
  wizardSetNewTitleBlockStyle: typeof wizardSetNewTitleBlockStyle;
  wizardSetNewTemplatePosition: typeof wizardSetNewTemplatePosition;
};

type AttributeEditorProps = {
  graph: mxGraph;
} & PropsFromState &
  PropsFromDispatch;

const AttributeEditor = ({
  graph,
  paperSize,
  paperSizeData,
  titleBlockData,
  wizardUpdatePaperSizeData,
  wizardSetTitleBlockData,
  wizardSetNewTitleBlockStyle,
  wizardSetNewTemplatePosition,
  isViewOnly,
  whoamiData,
}: AttributeEditorProps) => {
  const [cell, setCell] = useState<mxCell>();
  const [templateCell, setTemplateCell] = useState<mxCell>();
  const [currentText, setCurrentText] = useState<string>('');
  // const [currentLink, setCurrentLink] = useState<string>('');
  const [currentFontSize, setCurrentFontSize] = useState<number>(14);
  const [currentFontColor, setCurrentFontColor] = useState<string>('#000000');
  const [currentBackgroundColor, setCurrentBackgroundColor] = useState<string>('#ffffff');
  const [currentStrokeColor, setCurrentStrokeColor] = useState<string>('#000000');
  const [currentStrokeWidth, setCurrentStrokeWidth] = useState<number>(1);
  const [currentOpacity, setCurrentOpacity] = useState<number>(100);
  const [currentObjectWidth, setCurrentObjectWidth] = useState<number>(0);
  const [currentObjectHeight, setCurrentObjectHeight] = useState<number>(0);
  const [currentObjectX, setCurrentObjectX] = useState<number>(0);
  const [currentObjectY, setCurrentObjectY] = useState<number>(0);
  const [currentLink, setCurrentLink] = React.useState<string>('');

  const setCellHandler = (cell: mxCell) => {
    if (cell) {
      const isTemplateLogo = cell.id.includes('template_logo');
      const isTemplateWatermark = cell.id.includes('template_watermark');
      const isSourcePort = cell.source ? cell.source.id.includes('draw_port') : false;
      const isTargetPort = cell.target ? cell.target.id.includes('draw_port') : false;

      // Disable for template logo and schematics wire connections
      if (isTemplateLogo || isTemplateWatermark || (isSourcePort && isTargetPort)) {
        setCell(undefined);
      } else {
        setCell(cell);

        const isLink = cell?.id.includes('link');
        const cellValue =
          typeof cell?.value === 'string' ? cell.value : cell?.value?.getAttribute('label') || '';

        if (isLink) {
          // Extract current link and label using regex
          const currentLinktemp =
            (cellValue.match(/href="([^"]+)"/) && cellValue.match(/href="([^"]+)"/)[1]) || '';
          setCurrentLink(currentLinktemp);
          // const currentLabel =
          //   (cellValue.match(/>([^<]+)<\/a>/) && cellValue.match(/>([^<]+)<\/a>/)[1]) ||
          //   currentText; // Extract label
          // setCurrentText(currentLabel);
        }
        const currentLabel =
          cellValue.match(/>([^<]+)<\/a>/) && cellValue.match(/>([^<]+)<\/a>/)[1];
        // console.log(currentLabel, 'current lable');

        setCurrentText(
          isLink
            ? currentLabel
            : mx.mxUtils.isNode(cell.value, 'component')
            ? cell.value.getAttribute('label')
            : cell.value,
        );

        const style = cell.getStyle();
        const styles = style ? style.split(';') : [];
        const fontSize = styles.find((s: string) => s.includes('fontSize'));
        const fontSizeValue = fontSize ? parseInt(fontSize.split('=')[1]) : 14;
        setCurrentFontSize(fontSizeValue);

        const fontColor = styles.find((s: string) => s.includes('fontColor'));
        const fontColorValue = fontColor ? fontColor.split('=')[1] : '#000000';
        setCurrentFontColor(fontColorValue);

        const backgroundColor = styles.find((s: string) => s.includes('fillColor'));
        const backgroundColorValue = backgroundColor ? backgroundColor.split('=')[1] : '#ffffff';
        setCurrentBackgroundColor(backgroundColorValue);

        const strokeColor = styles.find((s: string) => s.includes('strokeColor'));
        const strokeColorValue = strokeColor ? strokeColor.split('=')[1] : '#000000';
        setCurrentStrokeColor(strokeColorValue);

        const strokeWidth = styles.find((s: string) => s.includes('strokeWidth'));
        const strokeWidthValue = strokeWidth ? parseInt(strokeWidth.split('=')[1]) : 1;
        setCurrentStrokeWidth(strokeWidthValue);

        const opacity = styles.find((s: string) => s.includes('opacity'));
        const opacityValue = opacity ? parseInt(opacity.split('=')[1]) : 100;
        setCurrentOpacity(opacityValue);
      }
    } else {
      setCell(undefined);
    }
  };
  // console.log(currentText, 'current text');

  const setTemplateCellHandler = (cell: mxCell) => {
    if (cell) {
      const isLogo = cell.id.includes('logo');
      const isTemplate = cell.id.includes('template');
      const isWatermark = cell.id.includes('watermark');

      if ((isLogo || isTemplate) && !isWatermark) {
        setCurrentObjectWidth(cell.geometry.width);
        setCurrentObjectHeight(cell.geometry.height);
        setCurrentObjectX(cell.geometry.x);
        setCurrentObjectY(cell.geometry.y);
        setTemplateCell(cell);
      } else {
        setTemplateCell(undefined);
      }
    } else {
      setTemplateCell(undefined);
    }
  };

  const updateStyleRelatedCell = (id: string, style: string) => {
    const idAlias = id.split('_').slice(0, 3).join('_');
    const cells: mxCell[] = Object.values(graph?.model.cells);

    if (cells) {
      cells.forEach((cell) => {
        if (cell.id.includes(idAlias)) {
          cell.setStyle(style);
        }
      });
    }
  };

  const updateTitleBlockStyle = (style: string) => {
    if (cell?.id.includes('template_title-block')) {
      const id = cell.id.split('_');
      const key = id[2];

      const foundIndex = titleBlockData.findIndex((data) => data.key === key);

      if (foundIndex !== -1) {
        titleBlockData[foundIndex].style = style;
      }

      wizardSetTitleBlockData(titleBlockData);
      wizardSetNewTitleBlockStyle(titleBlockData);
      updateStyleRelatedCell(cell.id, style);
    }
  };

  const getNewTemplateData = () => {
    if (templateCell) {
      const newData = _.cloneDeep(paperSizeData[paperSize]);
      const id = templateCell.id.split('_');
      const typeObject = id[1];
      const index = id[2];

      const newGeometry = {
        x: currentObjectX,
        y: currentObjectY,
        width: currentObjectWidth,
        height: currentObjectHeight,
      };

      switch (typeObject) {
        case 'logo':
          newData.logoGeometry = newGeometry;
          break;
        case 'title-block':
          newData.titleBlockGeometry[index as keyof TitleBlockGeometry] = newGeometry;
          break;
      }

      return newData;
    }

    return null;
  };

  const updateTemplatePosition = () => {
    const newTemplateData = getNewTemplateData();

    if (newTemplateData) {
      wizardSetNewTemplatePosition(newTemplateData);
    }
  };

  const updateTemplateEditorHandler = async () => {
    const newTemplateData = getNewTemplateData();

    if (newTemplateData) {
      const data = _.cloneDeep(paperSizeData);
      data[paperSize] = newTemplateData;
      await putUpdateTemplateSettings('', {
        template_settings: JSON.stringify(data),
      });
      wizardUpdatePaperSizeData(data);
      message.success(`Template updated for ${paperSize} size`);
    }
  };

  useEffect(() => {
    graph.addListener(mx.mxEvent.CLICK, function (_sender, evt) {
      const cell = evt.getProperty('cell'); // cell may be null

      setCellHandler(cell);
      setTemplateCellHandler(cell);
    });
  }, [graph]);

  const renderFontEditor = () => {
    const isDimensionLine = cell?.id.includes('dimension_line');
    const isLink = cell?.id.includes('link');

    // const cellValue =
    //   typeof cell?.value === 'string' ? cell.value : cell?.value?.getAttribute('label') || '';

    const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setCurrentText(value);

      if (mx.mxUtils.isNode(cell?.value, 'component')) {
        setCurrentText(value);
        const cellValue = cell?.value;
        cellValue.setAttribute('label', value);
        cell?.setValue(cellValue);
      } else {
        setCurrentText(value);
        // cell?.setValue(value);
        if (isLink) {
          const newValue = `<a href="${currentLink}" target="_blank">${e.target.value}</a>`;
          cell?.setValue(newValue);
        } else {
          cell?.setValue(value);
        }
      }

      if (cell?.id.includes('template_title-block')) {
        const id = cell.id.split('_');
        const key = id[2];

        const foundIndex = titleBlockData.findIndex((data) => data.key === key);

        if (foundIndex !== -1) {
          titleBlockData[foundIndex].fieldValue = value;
        }

        wizardSetTitleBlockData(titleBlockData);
      }

      graph?.refresh();
    };

    const handleLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      // currentLink = e.target.value;
      // console.log(currentLink, '-----', e.target.value, 'onchange ');

      setCurrentLink(e.target.value);
      // console.log(linkValue, 'link input');
      // currentLink = linkValue;
      // Update the cell's value to include the new link

      // setCurrentLink(linkValue);
      const newValue = `<a href="${e.target.value}" target="_blank">${currentText}</a>`;
      cell?.setValue(newValue);
      graph?.refresh(); // Refresh the graph to reflect changes
    };
    // console.log(cellValue);

    const handleFontSizeChange = (value: number | null) => {
      if (typeof value === 'number' && cell) {
        setCurrentFontSize(value);
        const newStyle = replaceStyles(cell, [
          {
            name: 'fontSize',
            value: `${value}`,
          },
        ]);

        updateTitleBlockStyle(newStyle);

        cell.setStyle(newStyle);
        graph?.refresh();
      }
    };

    const handleFontColorChange = (color: string) => {
      if (cell) {
        setCurrentFontColor(color);
        const newStyle = replaceStyles(cell, [
          {
            name: 'fontColor',
            value: color,
          },
        ]);

        updateTitleBlockStyle(newStyle);

        cell.setStyle(newStyle);
        graph?.refresh();
      }
    };

    return (
      <Space direction="vertical" style={{ padding: '10px' }}>
        <Space direction="vertical">
          <label>Text:</label>
          {/* <Input
            disabled={isDimensionLine}
            value={currentText}
            onChange={(e) => handleTextChange(e)}
          /> */}
          {isLink ? (
            <>
              <Input value={currentText} onChange={handleTextChange} placeholder="Enter label" />
              <label>Link:</label>
              <Input value={currentLink} onChange={handleLinkChange} placeholder="Enter link URL" />
            </>
          ) : (
            <Input disabled={isDimensionLine} value={currentText} onChange={handleTextChange} />
          )}
          {isDimensionLine && <Tag color="red">You can edit via modal</Tag>}
        </Space>
        <Space direction="vertical">
          <label>Font Size:</label>
          <InputNumber
            defaultValue={12}
            min={1}
            value={currentFontSize}
            onChange={handleFontSizeChange}
          />
        </Space>
        <Space direction="vertical">
          <label>Font Color:</label>
          <PopoverPicker color={currentFontColor} onChange={handleFontColorChange} />
        </Space>
      </Space>
    );
  };

  const renderBlockEditor = () => {
    const handleBackgroundColorChange = (color: string) => {
      if (cell) {
        setCurrentBackgroundColor(color);
        const newStyle = replaceStyles(cell, [
          {
            name: 'fillColor',
            value: color,
          },
        ]);

        updateTitleBlockStyle(newStyle);

        cell.setStyle(newStyle);
        graph?.refresh();
      }
    };

    const handleStrokeColorChange = (color: string) => {
      if (cell) {
        setCurrentStrokeColor(color);
        const newStyle = replaceStyles(cell, [
          {
            name: 'strokeColor',
            value: color,
          },
        ]);

        updateTitleBlockStyle(newStyle);

        cell.setStyle(newStyle);
        graph?.refresh();
      }
    };

    const handleStrokeWidthChange = (value: number | null) => {
      if (typeof value === 'number' && cell) {
        setCurrentStrokeWidth(value);
        const newStyle = replaceStyles(cell, [
          {
            name: 'strokeWidth',
            value: String(value),
          },
        ]);

        updateTitleBlockStyle(newStyle);

        cell.setStyle(newStyle);
        graph?.refresh();
      }
    };

    const handleOpacityChange = (value: number | null) => {
      if (typeof value === 'number' && cell) {
        setCurrentOpacity(value);
        const newStyle = replaceStyles(cell, [
          {
            name: 'opacity',
            value: String(value),
          },
        ]);

        updateTitleBlockStyle(newStyle);

        cell.setStyle(newStyle);
        graph?.refresh();
      }
    };

    return (
      <Space direction="vertical" style={{ padding: '10px' }}>
        <Space direction="vertical">
          <label>Stroke Width:</label>
          <InputNumber min={1} value={currentStrokeWidth} onChange={handleStrokeWidthChange} />
        </Space>
        <Space direction="vertical">
          <label>Opacity:</label>
          <InputNumber min={1} value={currentOpacity} onChange={handleOpacityChange} />
        </Space>
        <Space direction="vertical">
          <label>Background Color:</label>
          <PopoverPicker color={currentBackgroundColor} onChange={handleBackgroundColorChange} />
        </Space>
        <Space direction="vertical">
          <label>Stroke Color:</label>
          <PopoverPicker color={currentStrokeColor} onChange={handleStrokeColorChange} />
        </Space>
      </Space>
    );
  };

  const renderTemplateEditor = () => {
    const handleWidthChange = (value: number | null) => {
      if (typeof value === 'number' && templateCell) {
        setCurrentObjectWidth(value);
        const geometry = templateCell.geometry;
        geometry.width = value;
        templateCell.setGeometry(geometry);
        graph?.refresh();

        updateTemplatePosition();
      }
    };

    const handleHeightChange = (value: number | null) => {
      if (typeof value === 'number' && templateCell) {
        setCurrentObjectHeight(value);
        const geometry = templateCell.geometry;
        geometry.height = value;
        templateCell.setGeometry(geometry);
        graph?.refresh();

        updateTemplatePosition();
      }
    };

    const handleXChange = (value: number | null) => {
      if (typeof value === 'number' && templateCell) {
        setCurrentObjectX(value);
        const geometry = templateCell.geometry;
        geometry.x = value;
        templateCell.setGeometry(geometry);
        graph?.refresh();

        updateTemplatePosition();
      }
    };

    const handleYChange = (value: number | null) => {
      if (typeof value === 'number' && templateCell) {
        setCurrentObjectY(value);
        const geometry = templateCell.geometry;
        geometry.y = value;
        templateCell.setGeometry(geometry);
        graph?.refresh();

        updateTemplatePosition();
      }
    };

    return (
      <>
        <Space direction="vertical" style={{ padding: '10px' }}>
          <Space>
            <label>W:</label>
            <InputNumber min={1} value={currentObjectWidth} onChange={handleWidthChange} />
          </Space>
          <Space>
            <label>H:</label>
            <InputNumber min={1} value={currentObjectHeight} onChange={handleHeightChange} />
          </Space>
        </Space>
        <Space direction="vertical" style={{ padding: '10px' }}>
          <Space>
            <label>X:</label>
            <InputNumber min={1} value={currentObjectX} onChange={handleXChange} />
          </Space>
          <Space>
            <label>Y:</label>
            <InputNumber min={1} value={currentObjectY} onChange={handleYChange} />
          </Space>
        </Space>
        <Space style={{ padding: '10px' }}>
          <Tooltip title={!whoamiData && 'Only for registered user.'}>
            <Button
              disabled={isViewOnly || !whoamiData}
              type="primary"
              onClick={() => updateTemplateEditorHandler()}
            >
              Save As Default
            </Button>
          </Tooltip>
        </Space>
      </>
    );
  };

  const renderEmptyObject = () => {
    return (
      <span style={{ display: 'flex', justifyContent: 'center', padding: '1rem 0' }}>
        There's no selected object
      </span>
    );
  };

  return (
    <Collapse defaultActiveKey={['1', '2', '3']}>
      <Panel key="1" header="Font Editor">
        {cell && cell.id !== 'asbuiltdata' ? renderFontEditor() : renderEmptyObject()}
      </Panel>
      <Panel key="2" header="Block Editor">
        {cell && cell.id !== 'asbuiltdata' ? renderBlockEditor() : renderEmptyObject()}
      </Panel>
      <Panel key="3" header="Template Editor">
        {templateCell ? renderTemplateEditor() : renderEmptyObject()}
      </Panel>
    </Collapse>
  );
};

const mapStateToProps = (state: AppState) => ({
  paperSize: state.assemblyWizard.paperSize,
  paperSizeData: state.assemblyWizard.paperSizeData,
  titleBlockData: state.assemblyWizard.titleBlockData,
  isViewOnly: state.assemblyWizard.isViewOnly,
  whoamiData: state.data.whoami.data,
});

const mapDispatchToProps = {
  wizardUpdatePaperSizeData,
  wizardSetTitleBlockData,
  wizardSetNewTitleBlockStyle,
  wizardSetNewTemplatePosition,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttributeEditor);
