import { mxCell, mxGraph } from '@anekonnect/mxgraph';
import { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { AppState } from '~/store/reducers';
import { TitleBlockData } from '~/store/reducers/wizard/State';
import { PaperSizeData } from '~/utils/paperSize';

type PropsFromState = {
  titleBlockData: TitleBlockData[];
  paperSize: string;
  paperSizeData: PaperSizeData;
  drawingNumber: string;
  drawingRevision: string;
  drawingNumberdata: any;
};

type TitleBlockProps = {
  graph: mxGraph;
} & PropsFromState;

const TitleBlock = ({
  graph,
  titleBlockData,
  paperSize,
  paperSizeData,
  drawingNumber,
  drawingRevision,
  drawingNumberdata,
}: TitleBlockProps) => {
  const prefix = 'template_title-block';

  const addTexts = useCallback(() => {
    const paperData = paperSizeData[paperSize];

    const insertTextCell = (
      parent: mxCell,
      id: string,
      value: string | undefined,
      x: number,
      y: number,
      w: number,
      h: number,
      style: string,
    ) => {
      const cell = graph.model.getCell(id);

      if (value) {
        if (cell) {
          cell.setValue(value);
          cell.setStyle(style);
        } else {
          graph.insertVertex(parent, id, value, x, y, w, h, style);
        }
      } else {
        graph.model.remove(cell);
      }

      graph.refresh();
    };

    const parent = graph.getDefaultParent();
    graph.getModel().beginUpdate();

    try {
      titleBlockData.forEach((data) => {
        const key = data.key;
        const value = data.fieldValue;
        const fontSize = paperData.titleBlockGeometry[key]?.fontSize || 12;
        const fontColor = paperData.titleBlockGeometry[key]?.fontColor || '#000000';
        let x = 50;
        let y = 50;
        let width = 200;
        let height = 40;
        let style = `text;html=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;shadow=0;comic=0;fontFamily=Segoe UI;fontSize=${fontSize};fontColor=${fontColor};`;

        if (paperData.titleBlockGeometry[key]) {
          x = paperData.titleBlockGeometry[key].x;
          y = paperData.titleBlockGeometry[key].y;
          width = paperData.titleBlockGeometry[key].width;
          height = paperData.titleBlockGeometry[key].height;
        }

        if (data.style) {
          style = data.style;
        }

        insertTextCell(parent, `${prefix}_${key}_page_1`, value, x, y, width, height, style);
      });

      const drawingNumberKey = 'assemblyDrawingNumber';
      const drawingRevisionKey = 'assemblyRevision';
      const fontSize = paperData.titleBlockGeometry[drawingNumberKey]?.fontSize || 12;
      const fontColor = paperData.titleBlockGeometry[drawingNumberKey]?.fontColor || '#000000';
      const defaultStyle = `text;html=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;shadow=0;comic=0;fontFamily=Segoe UI;fontSize=${fontSize};fontColor=${fontColor};`;

      insertTextCell(
        parent,
        `${prefix}_${drawingNumberKey}_page_1`,
        `${drawingNumberdata.prefix}${drawingNumber}${drawingNumberdata.suffix}`,
        paperData.titleBlockGeometry[drawingNumberKey].x,
        paperData.titleBlockGeometry[drawingNumberKey].y,
        paperData.titleBlockGeometry[drawingNumberKey].width,
        paperData.titleBlockGeometry[drawingNumberKey].height,
        titleBlockData.find((data) => data.key === drawingNumberKey)?.style || defaultStyle,
      );

      insertTextCell(
        parent,
        `${prefix}_${drawingRevisionKey}_page_1`,
        drawingRevision,
        paperData.titleBlockGeometry[drawingRevisionKey].x,
        paperData.titleBlockGeometry[drawingRevisionKey].y,
        paperData.titleBlockGeometry[drawingRevisionKey].width,
        paperData.titleBlockGeometry[drawingRevisionKey].height,
        titleBlockData.find((data) => data.key === drawingRevisionKey)?.style || defaultStyle,
      );
    } finally {
      graph.getModel().endUpdate();
    }
  }, [
    graph,
    titleBlockData,
    paperSize,
    paperSizeData,
    drawingNumber,
    drawingRevision,
    drawingNumberdata,
  ]);

  useEffect(addTexts, [addTexts]);

  return null;
};

const mapStateToProps = (state: AppState) => {
  return {
    titleBlockData: state.assemblyWizard.titleBlockData,
    paperSize: state.assemblyWizard.paperSize,
    paperSizeData: state.assemblyWizard.paperSizeData,
    drawingNumber: state.assemblyWizard.drawingNumber,
    drawingRevision: state.assemblyWizard.drawingRevision,
    drawingNumberdata: state.assemblyWizard.drawingnumberdata,
  };
};

export default connect(mapStateToProps)(TitleBlock);
