import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { camelCase } from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import styles from './WizardTitleBlock.module.scss';

import { putUpdateTitleBlockSchema } from '~/api/AuthorizedPuts';
import {
  wizardSetDrawingNumber,
  wizardSetDrawingNUmberData,
  wizardSetDrawingRevision,
  wizardSetTitleBlockData,
} from '~/store/actions/wizard/Action';
import { AppState } from '~/store/reducers';
import { DrawingnumberData, TitleBlockData } from '~/store/reducers/wizard/State';
import { Prefix } from '~/api/API';

const getNewSchema = (titleBlockData: TitleBlockData[]) => {
  return titleBlockData.map((data) => {
    return {
      key: data.key,
      fieldName: data.fieldName,
      fieldValue: '',
    };
  });
};

type EditableCellProps = {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: 'number' | 'text';
  record: TitleBlockData;
  index: number;
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLElement>;

const EditableCell = ({
  editing,
  dataIndex,
  inputType,
  children,
  ...restProps
}: EditableCellProps) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item name={dataIndex} style={{ margin: 0 }}>
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

type PropsFromState = {
  titleBlockData: TitleBlockData[];
  whoamiData: {
    name?: string;
    payment_status?: string;
    type?: string;
  };
  drawingNumber: string;
  drawingnumberdata: DrawingnumberData;
  drawingRevision: string;
  tenant: {
    is_auto_drawing_number?: boolean;
    is_drawing_prefix?: boolean;
    is_drawing_suffix?: boolean;
    prefix?: Prefix;
    suffix?: Prefix;
  };
};

type PropsFromDispatch = {
  wizardSetTitleBlockData: typeof wizardSetTitleBlockData;
  wizardSetDrawingNumber: typeof wizardSetDrawingNumber;
  wizardSetDrawingNUmberData: typeof wizardSetDrawingNUmberData;
  wizardSetDrawingRevision: typeof wizardSetDrawingRevision;
};

type WizardTitleBlockProps = PropsFromState & PropsFromDispatch;

const WizardTitleBlock = ({
  titleBlockData,
  whoamiData,
  drawingNumber,
  drawingnumberdata,
  drawingRevision,
  tenant,
  wizardSetTitleBlockData,
  wizardSetDrawingNumber,
  wizardSetDrawingRevision,
  wizardSetDrawingNUmberData,
}: WizardTitleBlockProps) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');

  const updateTitleBlockSchema = async (titleBlockData: TitleBlockData[]) => {
    const newSchema = getNewSchema(titleBlockData);

    return await putUpdateTitleBlockSchema('', {
      title_block_schema: JSON.stringify(newSchema),
    });
  };

  const handleAdd = async () => {
    const field = `New Field ${Date.now()}`;

    const newData = {
      key: camelCase(field),
      fieldName: field,
      fieldValue: '',
    };

    wizardSetTitleBlockData([...titleBlockData, newData]);

    if (whoamiData) {
      await updateTitleBlockSchema([...titleBlockData, newData]);
    }

    message.success('New Title Block Added');
  };

  const handleDelete = async (key: React.Key) => {
    const newData = titleBlockData.filter((item) => item.key !== key);
    wizardSetTitleBlockData(newData);

    if (whoamiData) {
      await updateTitleBlockSchema(newData);
    }

    message.success(`Title Block Deleted`);
  };

  const isEditing = (record: TitleBlockData) => record.key === editingKey;

  const handleEdit = (record: Partial<TitleBlockData> & { key: React.Key }) => {
    form.setFieldsValue({ fieldName: '', fieldValue: '', ...record });
    setEditingKey(record.key);
  };

  const handleCancel = () => {
    setEditingKey('');
  };

  const handleSave = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as TitleBlockData;

      const newData = [...titleBlockData];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        wizardSetTitleBlockData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        wizardSetTitleBlockData(newData);
        setEditingKey('');
      }

      if (whoamiData) {
        await updateTitleBlockSchema(newData);
      }

      message.success('Title Block Saved');
    } catch (errInfo) {
      // console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: 'Field Name',
      dataIndex: 'fieldName',
      width: '25%',
      editable: true,
    },
    {
      title: 'Field Value',
      dataIndex: 'fieldValue',
      width: '40%',
      editable: true,
    },
    {
      title: 'Operation',
      dataIndex: 'operation',
      width: '10%',
      render: (_: unknown, record: TitleBlockData) => {
        const editable = isEditing(record);

        return editable ? (
          <Space>
            <Typography.Link onClick={() => handleSave(record.key)}>Save</Typography.Link>
            <Typography.Link onClick={handleCancel}>Cancel</Typography.Link>
          </Space>
        ) : (
          <Space>
            <Typography.Link disabled={editingKey !== ''} onClick={() => handleEdit(record)}>
              Edit
            </Typography.Link>
            <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
              <Typography.Link>Delete</Typography.Link>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record: TitleBlockData) => ({
        record,
        inputType: col.dataIndex === 'typeNumber' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const excludeFields = (data: TitleBlockData[]) => {
    const exclude = ['assemblyDrawingNumber', 'assemblyRevision'];

    return data.filter((item) => !exclude.includes(item.key));
  };

  const updateDrawingNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    wizardSetDrawingNumber(e.target.value);
  };

  const updateDrawingRevision = (e: React.ChangeEvent<HTMLInputElement>) => {
    wizardSetDrawingRevision(e.target.value);
  };

  return (
    <Form component={false} form={form}>
      <div className={styles.topWrapper}>
        <Tooltip
          placement="right"
          title="New title block field will be added to the end of the list"
          arrowPointAtCenter
        >
          <Button type="primary" onClick={handleAdd}>
            Add New Title Block Field
          </Button>
        </Tooltip>
        <Space wrap>
          {tenant.is_drawing_prefix && (
            <Space>
              <Typography.Text strong>Prefix</Typography.Text>
              <Select
                style={{ width: 80 }}
                value={drawingnumberdata.prefix}
                onChange={(value) => {
                  wizardSetDrawingNUmberData({ prefix: value, suffix: drawingnumberdata.suffix });
                }}
              >
                <Select.Option value="">{''}</Select.Option>
                {tenant.prefix?.value.map((data, index) => {
                  return (
                    <Select.Option key={index} value={data}>
                      {data}
                    </Select.Option>
                  );
                })}
              </Select>
            </Space>
          )}

          <Space>
            <Typography.Text strong>Drawing Number:</Typography.Text>
            <Input
              disabled={tenant.is_auto_drawing_number}
              placeholder="Input Drawing Number"
              value={drawingNumber}
              onChange={updateDrawingNumber}
            />
          </Space>
          {tenant.is_drawing_suffix && (
            <Space>
              <Typography.Text strong>Suffix</Typography.Text>
              <Select
                style={{ width: 80 }}
                value={drawingnumberdata.suffix}
                onChange={(value) => {
                  wizardSetDrawingNUmberData({ prefix: drawingnumberdata.prefix, suffix: value });
                }}
              >
                <Select.Option value="">{''}</Select.Option>
                {tenant.suffix?.value.map((data, index) => {
                  return (
                    <Select.Option key={index} value={data}>
                      {data}
                    </Select.Option>
                  );
                })}
              </Select>
            </Space>
          )}

          <Space>
            <Typography.Text strong>Drawing Revision:</Typography.Text>
            <Input
              placeholder="Input Drawing Revision"
              value={drawingRevision}
              onChange={updateDrawingRevision}
            />
          </Space>
        </Space>
      </div>

      <Table
        columns={mergedColumns}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        dataSource={excludeFields(titleBlockData)}
        pagination={{
          onChange: handleCancel,
          position: ['bottomCenter'],
        }}
        rowClassName="editable-row"
        style={{ marginTop: '1rem' }}
        bordered
      />
    </Form>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    titleBlockData: state.assemblyWizard.titleBlockData,
    drawingNumber: state.assemblyWizard.drawingNumber,
    drawingnumberdata: state.assemblyWizard.drawingnumberdata,
    drawingRevision: state.assemblyWizard.drawingRevision,
    whoamiData: state.data.whoami.data,
    tenant: state.data.tenantConfig.data,
  };
};

const mapDispatchToProps = {
  wizardSetTitleBlockData,
  wizardSetDrawingNumber,
  wizardSetDrawingRevision,
  wizardSetDrawingNUmberData,
};

export default connect(mapStateToProps, mapDispatchToProps)(WizardTitleBlock);
